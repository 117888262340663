<template lang="pug">
  b-col.extfood(md="4")
    h4 {{ group.name }}
    b-card
      b-form-checkbox-group(
        id="'food'+item.id"
        v-for="item in items[group.id]"
        :key="item.id"
        :checked="active[item.id]"
      )
        b-form-checkbox(
          v-model="active[item.id]"
          @change="manage_item(item)"
        ) {{ item.name }}
        span.price €{{ item.price }}

        div.qta.horizontal.d-flex(
          v-if="active[item.id] && find_extfood_index_by_id(item.id) > -1"
          style="margin-bottom: 20px"
        )
          b-button(variant="secondary" @click="chg_qta(-1, item.id)") -
          b-form-input(
            type="number" min="1" max="99" name="quantity"
            v-model="extfoods[find_extfood_index_by_id(item.id)]['qty']"
            @input="check_qta(item.id)"
          )
          b-button(variant="secondary" @click="chg_qta(1, item.id)") +

</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ExtfoodCart",
  props: ["group", "items"],
  data() {
    return {
      active: {}
    };
  },
  computed: {
    ...mapGetters("cart", ["extfoods"])
  },
  methods: {
    add_item(_item) {
      const item = {
        qty: 1,
        price: parseFloat(_item.price),
        name: _item.name,
        id: _item.id
      };

      this.$store.dispatch("cart/add_item", { type: "extfood", item });
    },
    remove_item(item) {
      this.$store.dispatch("cart/remove_item", {
        type: "extfood",
        item: item.id
      });
    },
    manage_item(item) {
      if (this.active[item.id].length == 1) {
        this.add_item(item);
      } else {
        this.remove_item(item);
      }
    },
    find_extfood_index_by_id(id) {
      let i = 0;
      for (const item of this.extfoods) {
        if (item.id == id) {
          return i;
        }

        i++;
      }

      return -1;
    },
    chg_qta(value, id) {
      let i = this.find_extfood_index_by_id(id);
      if (i < 0) {
        i = this.find_extfood_index_by_id(id);
      }
      let new_value = parseInt(this.extfoods[i]["qty"]) + value;
      if (!new_value) {
        new_value = 1;
      }

      this.$set(this.extfoods[i], "qty", new_value);

      this.make_total();
    },
    check_qta(id) {
      let i = this.find_extfood_index_by_id(id);
      if (i < 0) {
        i = this.find_extfood_index_by_id(id);
      }

      this.extfoods[i].qty = parseInt(this.extfoods[i].qty);
      if (!this.extfoods[i].qty || this.extfoods[i]["qty"] < 1) {
        this.extfoods[i]["qty"] = 1;
        this.$set(this.extfoods[i], "qty", 1);
      }

      this.make_total();
    },
    ...mapActions("cart", ["make_total"])
  }
};
</script>
