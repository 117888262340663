const moment = require("moment");

export default {
  namespaced: true,
  state: {
    stats: [],
    feedback_stats: [],
    orders: [],
    count: 0,
    settings: {},
  },
  getters: {
    stats: (state) => {
      const data = {
        labels: state.stats.map((i) => moment(i.x).format("DD/MM/YYYY")),
        datasets: [
          {
            label: "Ordini",
            data: [],
            backgroundColor: "#eed2d1",
            borderColor: "#eed2d1",
          },
        ],
      };

      state.stats.forEach((i) => {
        data.datasets[0].data.push(i.y);
      });

      return data;
    },
    feedback_stats: (state) => {
      const data = {
        labels: state.feedback_stats.map((i) =>
          moment(i.x).format("DD/MM/YYYY")
        ),
        datasets: [
          {
            label: "Recensioni",
            data: [],
            backgroundColor: "#eed2d1",
            borderColor: "#eed2d1",
          },
        ],
      };

      state.feedback_stats.forEach((i) => {
        data.datasets[0].data.push(i.y);
      });

      return data;
    },
    orders: (state) => {
      return state.orders;
    },
    count: (state) => {
      return state.count;
    },
    orders_settings: (state) => {
      return state.settings;
    },
    conzata: (state) => {
      return state.settings.placeholder_pizza;
    },
  },
  mutations: {
    save_stats: (state, data) => {
      state.stats = data;
    },
    save_feedback_stats: (state, data) => {
      state.feedback_stats = data;
    },
    save_orders: (state, data) => {
      state.orders = data["results"];
      state.count = data["count"];
    },
    save_settings: (state, settings) => {
      state.settings = settings;
    },
  },
  actions: {
    async stats({ commit, rootState, rootGetters }, payload) {
      await fetch(`${rootState.api}/orders/stats/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          if ((await response.status) == 200) {
            commit("save_stats", await response.json());
          }
        })
        .catch(() => {});
    },
    async feedback_stats({ commit, rootState, rootGetters }, payload) {
      await fetch(`${rootState.api}/feedbacks/stats/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          if ((await response.status) == 200) {
            commit("save_feedback_stats", await response.json());
          }
        })
        .catch(() => {});
    },
    async get_orders({ commit, rootState, rootGetters }, data) {
      let page = 1;
      let limit = 24;
      let query = "";

      if (data.p) {
        page = data.p;
      } else if (data.q) {
        limit = 1000;
        query = data.q;
      }

      let url = `${rootState.api}/orders/?limit=${limit}&offset=${
        (page - 1) * limit
      }`;

      if (data["filters"]) {
        for (const filter of Object.keys(data.filters)) {
          if (data.filters[filter]) {
            url += `&${filter}=${data.filters[filter]}`;
          }
        }
      }

      await fetch(url, {
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
      }).then(async (response) => {
        const items = await response.json();
        if (query == "") {
          commit("save_orders", items);
        } else {
          query = query.toLowerCase();
          const items_filtered = { count: 0, results: [] };
          for (const item of items.results) {
            let username;
            if (item.owner) {
              username = item.owner.username;
            } else {
              username = `${item.manual_data.name} ${item.manual_data.email}`;
            }

            if (
              item.id.toLowerCase().indexOf(query) > -1 ||
              username.toLowerCase().indexOf(query) > -1 ||
              item.price.toLowerCase().indexOf(query) > -1 ||
              item.pizzas.filter(
                (x) => x.name.toLowerCase().indexOf(query) > -1
              ).length > 0
            ) {
              items_filtered.results.push(item);
            }
          }
          commit("save_orders", items_filtered);
        }
      });
    },
    async print({ commit, dispatch, rootState, rootGetters }, order) {
      commit("loading_status", true, { root: true });

      let obj = null;
      await fetch(`${rootState.api}/orders/${order}/print/`, {
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
      })
        .then(async (response) => {
          if ((await response.status) != 200) {
            dispatch(
              "toast",
              {
                text: "Ordine non trovato",
                title: "Errore nella stampa",
                variant: "danger",
              },
              { root: true }
            );
          } else {
            obj = {
              filename: `conzatu-${order}.pdf`,
              blob: await response.blob(),
              headers: await response.headers,
            };
          }
        })
        .catch((e) => {
          dispatch(
            "toast",
            { text: e, title: "Errore nella stampa", variant: "danger" },
            { root: true }
          );
        });

      commit("loading_status", false, { root: true });
      return obj;
    },
    async edit_status({ commit, dispatch, rootState, rootGetters }, order) {
      commit("loading_status", true, { root: true });
      let status;

      await fetch(`${rootState.api}/orders/${order.id}/status/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify({ status: order.status }),
      })
        .then(async (response) => {
          status = await response.status;
        })
        .catch((e) => {
          dispatch(
            "toast",
            { text: e, title: "Errore nel salvataggio", variant: "danger" },
            { root: true }
          );
        });

      if (status != 200) {
        dispatch(
          "toast",
          {
            text: "Ordine non trovato",
            title: "Errore nel salvataggio",
            variant: "danger",
          },
          { root: true }
        );
      } else {
        dispatch(
          "toast",
          {
            text: "Ordine salvato con successo",
            title: "Ordine salvato!",
            variant: "success",
          },
          { root: true }
        );
      }

      commit("loading_status", false, { root: true });

      return status == 200;
    },
    async get_settings({ commit, rootState }) {
      let data;
      await fetch(`${rootState.api}/orders/settings/`).then(
        async (response) => {
          data = await response.json();
        }
      );

      commit("save_settings", data);
      return data;
    },
    async save_settings({ commit, dispatch, rootState, rootGetters }, payload) {
      let res = { status: 0, data: [] };
      await fetch(`${rootState.api}/orders/settings/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          res.status = await response.status;
          res.data = await response.json();
        })
        .catch((e) => {
          res.status = e.status;
          res.data = { detail: e.status };
        });

      let text = "Impostazioni salvate con successe",
        title = "Ordini salvati!",
        variant = "success";

      if (res.status == 200) {
        dispatch("toast", { text, title, variant }, { root: true });
        commit("save_settings", res.data);
      } else if (!res.data["detail"]) {
        dispatch("toast", { obj: res.data, variant }, { root: true });
      } else {
        text = res.data.detail;
        title = "Errore durante il salvataggio!";
        dispatch("toast", { text, title, variant }, { root: true });
      }
    },
    async create_order({ commit, dispatch, rootState, rootGetters }, payload) {
      commit("loading_status", true, { root: true });
      let status;

      await fetch(`${rootState.api}/orders/manual/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          status = await response.status;
        })
        .catch((e) => {
          dispatch(
            "toast",
            { text: e, title: "Errore nel salvataggio", variant: "danger" },
            { root: true }
          );
        });

      if (status != 201) {
        dispatch(
          "toast",
          {
            text: "Ordine non creato",
            title: "Errore nel salvataggio",
            variant: "danger",
          },
          { root: true }
        );
      } else {
        dispatch(
          "toast",
          {
            text: "Ordine salvato con successo",
            title: "Ordine salvato!",
            variant: "success",
          },
          { root: true }
        );
      }

      commit("loading_status", false, { root: true });

      return status == 201;
    },
    async mark_payed({ commit, dispatch, rootState, rootGetters }, order) {
      commit("loading_status", true, { root: true });
      let status;

      await fetch(`${rootState.api}/orders/${order}/payment/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
      })
        .then(async (response) => {
          status = await response.status;
        })
        .catch((e) => {
          dispatch(
            "toast",
            { text: e, title: "Errore nel salvataggio", variant: "danger" },
            { root: true }
          );
        });

      if (status != 200) {
        dispatch(
          "toast",
          {
            text: "Ordine non salvato",
            title: "Errore nel salvataggio",
            variant: "danger",
          },
          { root: true }
        );
      } else {
        dispatch(
          "toast",
          {
            text: "Ordine segnato come pagato!",
            title: "Ordine salvato!",
            variant: "success",
          },
          { root: true }
        );
      }

      commit("loading_status", false, { root: true });

      return status == 200;
    },
  },
};
