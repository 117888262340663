<template lang="pug">
  #extfoods.container-cz
    b-modal(
      ref="modal"
      id="new-extfood"
      :title="(form.id)?'Modifica' : 'Aggiungi'"
      centered
      :hide-footer="true"
    )
      validation-observer(ref="observer" v-slot="{ handleSubmit }")
        b-form(@submit.stop.prevent="handleSubmit(newExtfood)")
          b-row
            b-col(cols="6")
              form-input(
                label="Nome extra" v-model="form.name" type="text"
                :model="form.name" name="name"
                :rules="{required: true}"
                invalid_text="Il campo è obbligatorio"
              )
            b-col(cols="6")
              form-input(
                label="Breve descrizione" v-model="form.description" type="text"
                :model="form.description" name="description"
              )

            b-col(cols="6")
              form-input(
                label="Prezzo" v-model="form.price" type="number"
                :rules="{required: true}"
                invalid_text="Devi selezionare un prezzo"
                :model="form.price" name="price"
              )

            b-col(cols="6")
              b-form-checkbox(
                v-model="form.is_available" switch
              ) Disponibilità

            b-col(cols="6")
              form-input(
                label="Seleziona il gruppo" v-model="form.group" type="select"
                :model="form.group" name="group"
                :rules="{required: true}"
                invalid_text="Devi selezionare un gruppo"
                :options="extfood_groups"
              )

          .buttons-center
            b-button(type="submit") Salva
            a(href="#" @click="toggle") Torna indietro

    b-container
      section.header-filters
        div
          b-button(@click="new_extfood_modal" :disabled="is_busy")
            | Nuovo extra
          b-button(href="/a/extfoods/groups")
            | Gruppi extra
        #search-box
          b-form
            b-form-group(id="input-text-search")
              b-input(
                id="input-text-search"
                placeholder="ricerca"
                type="text"
                name="q"
                :value="$route.query.q"
              )
            b-button#search-btn(variant="light" type="submit")

    b-container
      section.table-actions
        a(@click="clearSelected" v-if="selected.length == extfoods.length")
          | Deseleziona tutto
        a(@click="selectAllRows" v-else) Seleziona tutto
        div(v-if="selected.length > 0")
          b-button(@click="delete_n_extfoods()")
            | Elimina {{ selected.length }} extra

      b-table(
        hover
        :items="extfoods"
        :fields="fields"
        :busy="is_busy"
        select-mode="multi"
        selectable
        @row-selected="onRowSelected"
        ref="table"
      )
        template(#table-busy)
          .text-center.my-2
            b-spinner.align-middle

        template(#cell(selected)="{ rowSelected }")
          template(v-if="rowSelected")
            span(aria-hidden="true") &check;

        template(#cell(is_available)="data")
          b-form-checkbox(
            v-model="data.item.is_available"
            switch
            @change="edit(data.item)"
          )

        template(#cell(group)="data")
          span {{ group_name(data.item.group) }}

        template(#cell(actions)="data")
          .actions
            img(
              src="@/assets/edit.svg"
              alt="Modifica"
              title="Modifica"
              @click="edit_extfood(data.item)"
            )
            img(
              src="@/assets/trash-red.png"
              alt="Elimina"
              title="Elimina"
              @click="delete_extfood(data.item.id)"
            )

    #pagination
      b-pagination(
        v-model="current_page" :total-rows="count" per-page="24"
        first-number last-number @change="change_pag($event)"
      )

</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Icon from "@/components/Icon.vue";
import FormInput from "@/components/FormInput.vue";

export default {
  name: "Extfoods",
  components: {
    icon: Icon,
    "form-input": FormInput
  },
  data() {
    return {
      is_busy: true,
      current_page: 1,
      page_num: 1,
      selected: [],
      fields: [
        {
          key: "selected",
          label: ""
        },
        {
          key: "id",
          label: "ID"
        },
        {
          key: "name",
          label: "Nome"
        },
        {
          key: "description",
          label: "Descrizione"
        },
        {
          key: "is_available",
          label: "Disponibilità"
        },
        {
          key: "price",
          label: "Prezzo"
        },
        {
          key: "group",
          label: "Gruppo"
        },
        {
          key: "actions",
          label: "Azioni"
        }
      ],
      form: {
        group: null,
        is_available: false
      }
    };
  },
  computed: {
    ...mapGetters("extfood", ["extfoods", "count", "groups", "group_name"]),
    ...mapGetters("extfood", { extfood_groups: "get_groups_for_select" })
  },
  methods: {
    change_pag(pagenum) {
      this.current_page = pagenum;
      window.location.href = this.$route.path + "?p=" + this.current_page;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.table.selectAllRows();
    },
    clearSelected() {
      this.$refs.table.clearSelected();
    },
    toggle() {
      this.$refs.modal.toggle();
    },
    new_extfood_modal() {
      this.form = { group: null, is_available: false };
      this.toggle();
    },
    edit_extfood(item) {
      this.form = { ...item };
      this.toggle();
    },
    delete_extfood(id) {
      this.$bvModal
        .msgBoxConfirm("Sicuro di volere eliminare questo extra?", {
          title: "Azione irreversibile",
          size: "sm",
          buttonSize: "lg",
          okVariant: "danger",
          okTitle: "Sì",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.delete({ id }).then(response => {
              if (response) {
                this.get_extfoods({ p: this.page_num });
              }
            });
          }
        })
        .catch(() => {});
    },
    async delete_n_extfoods() {
      let skip = false;
      await this.$bvModal
        .msgBoxConfirm(
          `Sicuro di volere eliminare ${this.selected.length} extra?`,
          {
            title: "Azione irreversibile",
            size: "sm",
            buttonSize: "lg",
            okVariant: "danger",
            okTitle: "Sì",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then(value => {
          skip = value;
        })
        .catch(() => {});

      if (!skip) return false;

      this.is_busy = true;
      for (const extfood of this.selected) {
        await this.delete({ id: extfood.id });
      }

      await this.get_extfoods({ p: this.page_num });
      this.is_busy = false;
    },
    newExtfood() {
      console.log(this.form);
      if (!this.form.id) {
        this.create(this.form).then(response => {
          if (response) {
            this.toggle();
          }
        });
      } else {
        this.edit(this.form).then(response => {
          if (response) {
            this.get_extfoods({ p: this.page_num });
            this.toggle();
          }
        });
      }
    },
    ...mapActions("extfood", [
      "get_extfoods",
      "get_groups",
      "edit",
      "create",
      "delete"
    ])
  },
  async mounted() {
    if (this.$route.query["q"]) {
      await this.get_extfoods({ q: this.$route.query.q });
    } else {
      this.page_num = parseInt(this.$route.query["p"]) || 1;

      await this.get_extfoods({ p: this.page_num });

      setTimeout(() => {
        this.$set(this.$data, "current_page", this.page_num);
      }, 1000);
    }

    this.get_groups();
    this.is_busy = false;
  }
};
</script>
