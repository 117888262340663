<template lang="pug">
  div#login
    #logo
    b-form
      validation-observer(ref="observer" v-slot="{ handleSubmit }")
        b-form(@submit.stop.prevent="handleSubmit(handleLogin)")
          h2 Accedi
          form-input(
            label="Username" v-model="form.username" type="text"
            :model="form.username" name="username"
            :rules="{required: true}"
            invalid_text="Il campo è obbligatorio"
          )
          form-input(
            label="Password" v-model="form.password" type="password"
            :model="form.password" name="password"
            :rules="{required: true}"
            invalid_text="Il campo è obbligatorio"
          )
          b-form-group
            a#forget-psw(href="#") Ho dimenticato la password
          b-form-group

          b-form-group._btn
            b-button(type="submit") Entra
</template>

<script>
import FormInput from "@/components/FormInput.vue";

import { mapActions } from "vuex";

export default {
  name: "Login",
  components: {
    "form-input": FormInput
  },
  data() {
    return {
      form: {}
    };
  },
  methods: {
    ...mapActions("auth", ["login"]),
    async handleLogin() {
      await this.login(this.form).then(async response => {
        if (await response) {
          setTimeout(() => {
            window.location.href = this.$route.query.ref || "/a/home";
          }, 2000);
        }
      });
    }
  }
};
</script>
