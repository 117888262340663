<template lang="pug">
  #rewards.container-cz
    b-modal(
      ref="modal"
      id="new-reward"
      :title="(form.id)?'Modifica' : 'Aggiungi'"
      centered
      :hide-footer="true"
    )
      validation-observer(ref="observer" v-slot="{ handleSubmit }")
        b-form(@submit.stop.prevent="handleSubmit(newReward)")
          b-row
            b-col(cols="6")
              form-input(
                label="Nome reward" v-model="form.name" type="text"
                :model="form.name" name="name"
                :rules="{required: true}"
                invalid_text="Il campo è obbligatorio"
              )
            b-col(cols="6")
              form-input(
                label="Breve descrizione" v-model="form.description" type="textarea"
                :model="form.description" name="description"
              )
            b-col(cols="6")
              b-form-checkbox(
                v-model="form.is_enabled" switch
              ) Abilitato
            b-col(cols="6")
              form-input(
                label="Punti necessari per riscattare il premio" v-model="form.cost" type="text"
                :model="form.cost" name="cost"
                :rules="{required: true}"
              )

          b-row(v-if="!form.id")
            b-col(cols="6")
              label Seleziona le dimensioni della pizza
              b-form-checkbox-group(
                v-for="item in pizza_sizes"
                :key="item.value"
              )
                b-form-checkbox(
                  v-model="form.pizzas[item.value]"
                ) {{ item.text }}
                div.qta.horizontal.d-flex(
                  v-if="form.pizzas[item.value] && form.pizzas[item.value].length > 0"
                  style="margin-bottom: 20px"
                )
                  b-button(variant="secondary" @click="chg_qta(-1, 'pizzas', item.value)") -
                  b-form-input(
                    type="number" min="1" max="99" name="quantity"
                    v-model="pizzas_qta[item.value]"
                    @change="check_qta('pizzas', item.value, $event)"
                  )
                  b-button(variant="secondary" @click="chg_qta(1, 'pizzas', item.value)") +


            b-col(cols="6")
              label Seleziona gli extra
              b-form-checkbox-group(
                v-for="item in extfood_groups"
                :key="item.value"
              )
                b-form-checkbox(
                  v-model="form.extfood[item.value]"
                ) {{ item.text }}
                div.qta.horizontal.d-flex(
                  v-if="form.extfood[item.value] && form.extfood[item.value].length > 0"
                  style="margin-bottom: 20px"
                )
                  b-button(variant="secondary" @click="chg_qta(-1, 'extfood', item.value)") -
                  b-form-input(
                    type="number" min="1" max="99" name="quantity"
                    v-model="extfood_qta[item.value]"
                    @change="check_qta('extfood', item.value, $event)"
                  )
                  b-button(variant="secondary" @click="chg_qta(1, 'extfood', item.value)") +

          .buttons-center
            b-button(type="submit") Salva
            a(href="#" @click="toggle") Torna indietro

    b-container
      section.header-filters
        b-button(@click="new_reward_modal" :disabled="is_busy")
          | Nuovo reward
        #search-box
          b-form
            b-form-group(id="input-text-search")
              b-input(
                id="input-text-search"
                placeholder="ricerca"
                type="text"
                name="q"
                :value="$route.query.q"
              )
            b-button#search-btn(variant="light" type="submit")

    b-container
      section.table-actions
        a(@click="clearSelected" v-if="selected.length == rewards.length")
          | Deseleziona tutto
        a(@click="selectAllRows" v-else) Seleziona tutto
        div(v-if="selected.length > 0")
          b-button(@click="delete_n_rewards()")
            | Elimina {{ selected.length }} rewards

      b-table(
        hover
        :items="rewards"
        :fields="fields"
        :busy="is_busy"
        select-mode="multi"
        selectable
        @row-selected="onRowSelected"
        ref="table"
      )
        template(#table-busy)
          .text-center.my-2
            b-spinner.align-middle

        template(#cell(selected)="{ rowSelected }")
          template(v-if="rowSelected")
            span(aria-hidden="true") &check;

        template(#cell(is_enabled)="data")
          b-form-checkbox(
            v-model="data.item.is_enabled"
            switch
            @change="edit(data.item)"
          )

        template(#cell(extfood)="data")
          span(v-for="extfood in data.item.extfood") {{ extfood.group.name }}, 

        template(#cell(pizzas)="data")
          span(v-for="pizza in data.item.pizzas") {{ pizza.pizza_size }}, 

        template(#cell(actions)="data")
          .actions
            img(
              src="@/assets/edit.svg"
              alt="Modifica"
              title="Modifica"
              @click="edit_reward(data.item)"
            )
            img(
              src="@/assets/trash-red.png"
              alt="Elimina"
              title="Elimina"
              @click="delete_reward(data.item.id)"
            )

    #pagination
      b-pagination(
        v-model="current_page" :total-rows="count" per-page="24"
        first-number last-number @change="change_pag($event)"
      )

</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Icon from "@/components/Icon.vue";
import FormInput from "@/components/FormInput.vue";

export default {
  name: "Rewards",
  components: {
    icon: Icon,
    "form-input": FormInput
  },
  data() {
    return {
      is_busy: true,
      current_page: 1,
      page_num: 1,
      selected: [],
      fields: [
        {
          key: "selected",
          label: ""
        },
        {
          key: "name",
          label: "Nome"
        },
        {
          key: "description",
          label: "Descrizione"
        },
        {
          key: "is_enabled",
          label: "Disponibilità"
        },
        {
          key: "cost",
          label: "Punti necessari per riscattare il premio"
        },
        {
          key: "extfood",
          label: "Extra"
        },
        {
          key: "pizzas",
          label: "Dimensioni pizza"
        },
        {
          key: "actions",
          label: "Azioni"
        }
      ],
      msg_settings: {
        title: "Azione irreversibile",
        size: "sm",
        buttonSize: "lg",
        okVariant: "danger",
        okTitle: "Sì",
        cancelTitle: "No",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true
      },
      form: { pizzas: [], extfood: [] },
      extfood_qta: {},
      pizzas_qta: {}
    };
  },
  computed: {
    ...mapGetters("rewards", ["rewards", "count"]),
    ...mapGetters("pizzas", { pizza_sizes: "sizes_for_select" }),
    ...mapGetters("extfood", { extfood_groups: "get_groups_for_select" })
  },
  methods: {
    change_pag(pagenum) {
      this.current_page = pagenum;
      window.location.href = this.$route.path + "?p=" + this.current_page;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.table.selectAllRows();
    },
    clearSelected() {
      this.$refs.table.clearSelected();
    },
    toggle() {
      this.$refs.modal.toggle();
    },
    new_reward_modal() {
      this.form = { extfood: [], pizzas: [] };
      this.toggle();
    },
    check_qta(model, id, new_value) {
      new_value = parseInt(new_value);
      switch (model) {
        case "extfood": {
          if (new_value <= 0) {
            this.form.extfood[id] = [];
            this.$set(this.form.extfood, id, []);
            this.$set(this.extfood_qta, id, 1);
          } else if (new_value == 1) {
            this.extfood_qta[id] = 1;
            this.$set(this.extfood_qta, id, 1);
          } else {
            this.extfood_qta[id] = new_value;
            this.$set(this.extfood_qta, id, new_value);
          }
          break;
        }
        case "pizzas": {
          if (new_value <= 0) {
            this.form.pizzas[id] = [];
            this.$set(this.form.pizzas, id, []);
            this.$set(this.pizzas_qta, id, 1);
          } else if (new_value == 1) {
            this.pizzas_qta[id] = 1;
            this.$set(this.pizzas_qta, id, 1);
          } else {
            this.pizzas_qta[id] = new_value;
            this.$set(this.pizzas_qta, id, new_value);
          }
          break;
        }
      }
    },
    chg_qta(value, model, id) {
      let old_value;
      if (model == "extfood") old_value = this.extfood_qta[id];
      else old_value = this.pizzas_qta[id];

      let new_value;
      if (old_value == undefined) {
        new_value = 1;
      } else if (Number(old_value) + value > 0) {
        new_value = Number(old_value) + value;
      } else {
        new_value = 0;
      }

      this.check_qta(model, id, new_value);
    },
    edit_reward(item) {
      this.form = { ...item };
      this.form.extfood = this.form.pizzas = null;
      this.toggle();
    },
    delete_reward(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Sicuro di volere eliminare questo reward?",
          this.msg_settings
        )
        .then(value => {
          if (value) {
            this.delete(id).then(response => {
              if (response) {
                this.get_rewards({ p: this.page_num });
              }
            });
          }
        })
        .catch(() => {});
    },
    async delete_n_rewards() {
      let skip = false;
      await this.$bvModal
        .msgBoxConfirm(
          `Sicuro di volere eliminare ${this.selected.length} rewards?`,
          this.msg_settings
        )
        .then(value => {
          skip = value;
        })
        .catch(() => {});

      if (!skip) return false;

      this.is_busy = true;
      for (const reward of this.selected) {
        await this.delete(reward.id);
      }

      await this.get_rewards({ p: this.page_num });
      this.is_busy = false;
    },
    newReward() {
      const payload = { ...this.form };
      delete payload.extfood;
      delete payload.pizzas;

      if (!this.form.id) {
        payload.extfood = [];
        payload.pizzas = [];

        for (const id in this.form.extfood) {
          if (this.form.extfood[id] && this.form.extfood[id].length > 0) {
            for (let i = 0; i < this.extfood_qta[id]; ++i) {
              payload.extfood.push(id);
            }
          }
        }

        for (const id in this.form.pizzas) {
          if (this.form.pizzas[id] && this.form.pizzas[id].length > 0) {
            for (let i = 0; i < this.pizzas_qta[id]; ++i) {
              payload.pizzas.push(id);
            }
          }
        }

        this.create(payload).then(response => {
          if (response) {
            this.toggle();
            window.location.reload();
          }
        });
      } else {
        this.edit(payload).then(response => {
          if (response) {
            this.get_rewards({ p: this.page_num });
            this.toggle();
          }
        });
      }
    },
    ...mapActions("rewards", ["get_rewards", "edit", "create", "delete"]),
    ...mapActions("pizzas", ["get_sizes"]),
    ...mapActions("extfood", ["get_groups"])
  },
  async mounted() {
    if (this.$route.query["q"]) {
      await this.get_rewards({ q: this.$route.query.q });
    } else {
      this.page_num = parseInt(this.$route.query["p"]) || 1;

      await this.get_rewards({ p: this.page_num });

      setTimeout(() => {
        this.$set(this.$data, "current_page", this.page_num);
      }, 1000);
    }
    await this.get_sizes().then(pizza_sizes => {
      for (const size of pizza_sizes) {
        this.$set(this.pizzas_qta, size.id, 1);
      }
    });
    await this.get_groups().then(extfood_groups => {
      for (const group of extfood_groups) {
        this.$set(this.extfood_qta, group.id, 1);
      }
    });

    this.is_busy = false;
  }
};
</script>
