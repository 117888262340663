import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import pizzas from "./modules/pizzas";
import info from "./modules/info";
import ingredients from "./modules/ingredients";
import doughs from "./modules/doughs";
import users from "./modules/users";
import rewards from "./modules/rewards";
import extfood from "./modules/extfood";
import promotions from "./modules/promotions";
import orders from "./modules/orders";
import cart from "./modules/cart";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    api: process.env.VUE_APP_BACKEND_URL,
    loading: false
  },
  getters: {
    loading: state => {
      return state.loading;
    }
  },
  mutations: {
    loading_status: (state, value) => {
      state.loading = value;
    }
  },
  actions: {
    async toggle_loading({ commit }, value) {
      commit("loading_status", value);
    },
    // eslint-disable-next-line
    async toast({}, data) {
      // data is { text: '', title: '', variant: ''}
      // If `data` has an `obj` field, there are multiple toast to display

      const vue = new Vue();
      if (data["obj"]) {
        for (const key of Object.keys(data.obj)) {
          if (typeof data.obj[key] == "object") {
            for (const message of data.obj[key]) {
              vue.$bvToast.toast(message, {
                title: key,
                variant: "danger",
                solid: true,
                toaster: "b-toaster-top-center"
              });
            }
          } else {
            vue.$bvToast.toast(data.obj[key], {
              title: "Errore",
              variant: "danger",
              solid: true,
              toaster: "b-toaster-top-center"
            });
          }
        }
      } else {
        vue.$bvToast.toast(data.text, {
          title: data.title,
          variant: data.variant,
          solid: true,
          toaster: "b-toaster-top-center"
        });
      }
    }
  },
  modules: {
    auth,
    pizzas,
    info,
    ingredients,
    doughs,
    users,
    rewards,
    extfood,
    promotions,
    orders,
    cart
  }
});
