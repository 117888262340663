export default {
  namespaced: true,
  state: {
    infos: {},
    orders_enabled: false,
  },
  getters: {
    info: (state) => {
      return state.infos;
    },
    are_orders_enabled: (state) => {
      return state.orders_enabled;
    },
  },
  mutations: {
    save_info: (state, data) => {
      state.infos = data;
    },
    save_orders_enabled: (state, value) => {
      state.orders_enabled = value;
    },
  },
  actions: {
    async get({ commit, rootState, rootGetters }) {
      // Get info from the backend
      commit("loading_status", true, { root: true });

      await fetch(`${rootState.api}/info/`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }).then(async (response) => {
        const data = await response.json();
        commit("save_info", data);
      });

      await fetch(`${rootState.api}/orders/settings/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
      }).then(async (response) => {
        const data = await response.json();
        commit("save_orders_enabled", data.are_orders_enabled);
      });

      commit("loading_status", false, { root: true });
    },
    async edit({ commit, dispatch, rootState, rootGetters }, data) {
      const payload = data.payload;
      let url = rootState.api;
      let method = "PUT";
      if (!data["url"]) url += "/info/";
      else url += data.url;

      if (data["method"]) method = data.method;

      commit("loading_status", true, { root: true });
      let res = { status: 0, data: null };

      await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          res.data = await response.json();
          res.status = await response.status;
        })
        .catch((e) => {
          res.status = e.status;
        });

      let text = "Modifiche salvate con successo",
        title = "Grande!",
        variant = "success";
      if (res.status == 200) {
        dispatch("toast", { text, title, variant }, { root: true });
      } else {
        text = res.data["detail"] || "Contatta un'admin";
        title = "Errore con il salvataggio";
        variant = "danger";

        dispatch("toast", { text, title, variant }, { root: true });
      }

      commit("loading_status", false, { root: true });
    },
    async edit_order_enable({ dispatch }, status) {
      const data = {
        url: "/orders/settings",
        method: "PATCH",
        payload: {
          are_orders_enabled: status,
        },
      };

      dispatch("edit", data);
    },
  },
};
