<template lang="pug">
  div
    #sidebar(v-if="sidebar")
      .logo
      ul
        li(:class="{active: active == 1}")
          a(href="/a/home") Home
        li(:class="{active: active == 2}")
          a(href="/a/users") Utenti
        li(:class="{active: active == 3}")
          a(href="/a/ingredients") Ingredienti
        li(:class="{active: active == 4}")
          a(href="/a/pizza") Pizza personalizzate
        ul(:class="{active: active == 11}")
          a(href="/a/extfoods") Extra
          li(:class="{active: inner_active == 111}")
            a(href="/a/extfoods/groups") Gruppi
        //- li(:class="{active: active == 10}")
        //-   a(href="/a/doughs") Impasti
        li(:class="{active: active == 5}")
          a(href="/a/statistics") Statistiche
        li(:class="{active: active == 6}")
          a(href="/") Corrieri
        ul(:class="{active: active == 7}")
          a(href="/a/orders") Ordini
          li(:class="{active: inner_active == 71}")
            a(href="/a/orders/rt") Ordini RT
        li(:class="{active: active == 8}")
          a(href="/a/rewards") Rewards
        li(:class="{active: active == 9}")
          a(href="/a/settings") Impostazioni

      b-button(@click="handleLogout") Logout
    header(:class="{center: !sidebar}")#mainheader
      h2 {{ title }}
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Header",
  props: ["active", "inner_active", "sidebar", "title"],
  methods: {
    ...mapActions("auth", ["logout"]),
    handleLogout() {
      this.logout();
      window.location.href = "/";
    }
  }
};
</script>
