export default {
  namespaced: true,
  state: {
    promotions: [],
    count: 0,
    groups: []
  },
  getters: {
    promotions: state => {
      return state.promotions;
    },
    count: state => {
      return state.count;
    }
  },
  mutations: {
    save_promotions: (state, data) => {
      state.promotions = data["results"];
      state.count = data["count"];
    },
    insert_promotion: (state, item) => {
      state.promotions.push(item);
      state.count++;
    }
  },
  actions: {
    async get_promotions({ commit, rootState, rootGetters }) {
      await fetch(`${rootState.api}/promotions/?limit=1000`, {
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        }
      }).then(async response => {
        const items = await response.json();
        commit("save_promotions", items);
      });
    },
    async edit({ commit, dispatch, rootState, rootGetters }, item) {
      commit("loading_status", true, { root: true });

      let res = { status: 0, data: {} };

      const form = new FormData();
      for (const key in item) {
        form.append(key, item[key]);
      }

      await fetch(`${rootState.api}/promotions/${item.id}/`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        },
        body: form
      })
        .then(async response => {
          res.data = await response.json();
          res.status = await response.status;
        })
        .catch(e => {
          res.data = { detail: "Errore generico, non sappiamo dove" };
          res.status = e.status;
        });

      let text = "Promozione modificata con successo",
        title = "Promozione salvata!",
        variant = "success";
      if (res.status == 200) {
        dispatch("toast", { text, title, variant }, { root: true });
      } else {
        variant = "danger";
        if (!res.data["detail"]) {
          dispatch("toast", { obj: res.data, variant }, { root: true });
        } else {
          text = res.data.detail;
          title = "Errore durante il salvataggio!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      }

      commit("loading_status", false, { root: true });

      if (res.status == 200) return true;
      return false;
    },
    async delete({ commit, dispatch, rootState, rootGetters }, id) {
      commit("loading_status", true, { root: true });

      let res = { status: 0, data: {} };

      await fetch(`${rootState.api}/promotions/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        }
      })
        .then(async response => {
          res.status = await response.status;
        })
        .catch(e => {
          res.status = e.status;
          res.data = { detail: e.status };
        });

      let text = "Promozione eliminato con successo",
        title = "Promozione eliminato!",
        variant = "success";
      if (res.status == 204) {
        dispatch("toast", { text, title, variant }, { root: true });
      } else {
        variant = "danger";

        if (!res.data["detail"]) {
          dispatch("toast", { obj: res.data, variant }, { root: true });
        } else {
          text = res.data.detail;
          title = "Errore durante il salvataggio!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      }

      commit("loading_status", false, { root: true });

      if (res.status == 204) return true;
      return false;
    },
    async create({ commit, dispatch, rootState, rootGetters }, item) {
      commit("loading_status", true, { root: true });

      let res = { status: 0, data: {} };

      const form = new FormData();
      for (const key in item) {
        form.append(key, item[key]);
      }

      await fetch(`${rootState.api}/promotions/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        },
        body: form
      })
        .then(async response => {
          res.data = await response.json();
          res.status = await response.status;
        })
        .catch(e => {
          res.status = e.status;
        });

      let text = "Promozione inserita con successo",
        title = "Promozione salvata!",
        variant = "success";
      if (res.status == 201) {
        dispatch("toast", { text, title, variant }, { root: true });

        commit("insert_promotion", res.data);
      } else {
        variant = "danger";
        if (!res.data["detail"]) {
          dispatch("toast", { obj: res.data, variant }, { root: true });
        } else {
          text = res.data.detail;
          title = "Errore durante il salvataggio!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      }

      commit("loading_status", false, { root: true });

      if (res.status == 201) return true;
      return false;
    }
  }
};
