const moment = require("moment");

export default {
  namespaced: true,
  state: {
    ingredients: [],
    count: 0,
    groups: [],
    stats: []
  },
  getters: {
    ingredients: state => {
      return state.ingredients;
    },
    count: state => {
      return state.count;
    },
    groups: state => {
      return state.groups;
    },
    groups_for_select: state => {
      return state.groups.map(x => {
        return { value: x.id, text: x.name };
      });
    },
    stats: state => {
      const data = {
        labels: state.stats.map(i => moment(i.x).format("DD/MM/YYYY")),
        datasets: [
          {
            label: "Ingredienti",
            data: [],
            backgroundColor: "#eed2d1",
            borderColor: "#eed2d1"
          }
        ]
      };

      state.stats.forEach(i => {
        data.datasets[0].data.push(i.y);
      });

      return data;
    }
  },
  mutations: {
    save_ingredients: (state, data) => {
      state.ingredients = data["results"];
      state.count = data["count"];
    },
    insert_ingredient: (state, item) => {
      state.ingredients.push(item);
      state.count++;
    },
    save_groups: (state, data) => {
      state.groups = data["results"];
    },
    save_stats: (state, data) => {
      state.stats = data;
    }
  },
  actions: {
    async get_ingredients({ commit, rootState }, data) {
      let page = 1;
      let limit = 24;
      let query = "";

      if (data.p) {
        page = data.p;
      } else if (data.q) {
        limit = 1000;
        query = data.q;
      }

      await fetch(
        `${rootState.api}/pizzas/ingredients/?limit=${limit}&offset=${(page -
          1) *
          limit}`
      ).then(async response => {
        const items = await response.json();
        if (query == "") {
          commit("save_ingredients", items);
        } else {
          query = query.toLowerCase();
          const items_filtered = { count: 0, results: [] };
          for (const item of items.results) {
            console.log(item.name, item.name.indexOf(query));
            if (item.name.toLowerCase().indexOf(query) > -1) {
              items_filtered.results.push(item);
            }
          }
          commit("save_ingredients", items_filtered);
        }
      });
    },
    async get_groups({ commit, rootState }) {
      await fetch(
        `${rootState.api}/pizzas/ingredients/groups/?limit=1000`
      ).then(async response => {
        commit("save_groups", await response.json());
      });
    },
    async edit({ commit, dispatch, rootState, rootGetters }, item) {
      commit("loading_status", true, { root: true });

      let res = { status: 0, data: {} };

      await fetch(`${rootState.api}/pizzas/ingredients/${item.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        },
        body: JSON.stringify(item)
      })
        .then(async response => {
          res.data = await response.json();
          res.status = await response.status;
        })
        .catch(e => {
          res.data = { detail: "Errore generico, non sappiamo dove" };
          res.status = e.status;
        });

      let text = "Ingrediente modificato con successo",
        title = "Ingrediente salvato!",
        variant = "success";
      if (res.status == 200) {
        dispatch("toast", { text, title, variant }, { root: true });

        commit("insert_ingredient", res.data);
      } else {
        variant = "danger";
        if (!res.data["detail"]) {
          dispatch("toast", { obj: res.data, variant }, { root: true });
        } else {
          text = res.data.detail;
          title = "Errore durante il salvataggio!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      }

      commit("loading_status", false, { root: true });

      if (res.status == 200) return true;
      return false;
    },
    async delete({ commit, dispatch, rootState, rootGetters }, id) {
      commit("loading_status", true, { root: true });

      let res = { status: 0, data: {} };

      await fetch(`${rootState.api}/pizzas/ingredients/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        }
      })
        .then(async response => {
          res.status = await response.status;
        })
        .catch(e => {
          res.status = e.status;
          res.data = { detail: e.status };
        });

      let text = "Ingrediente eliminato con successo",
        title = "Ingrediente eliminato!",
        variant = "success";
      if (res.status == 204) {
        dispatch("toast", { text, title, variant }, { root: true });
      } else {
        variant = "danger";

        if (!res.data["detail"]) {
          dispatch("toast", { obj: res.data, variant }, { root: true });
        } else {
          text = res.data.detail;
          title = "Errore durante il salvataggio!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      }

      commit("loading_status", false, { root: true });

      if (res.status == 204) return true;
      return false;
    },
    async create({ commit, dispatch, rootState, rootGetters }, form) {
      commit("loading_status", true, { root: true });

      let res = { status: 0, data: {} };

      await fetch(`${rootState.api}/pizzas/ingredients/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        },
        body: JSON.stringify(form)
      })
        .then(async response => {
          res.data = await response.json();
          res.status = await response.status;
        })
        .catch(e => {
          res.status = e.status;
        });

      let text = "Ingrediente inserito con successo",
        title = "Ingrediente salvato!",
        variant = "success";
      if (res.status == 201) {
        dispatch("toast", { text, title, variant }, { root: true });

        commit("insert_ingredient", res.data);
      } else {
        variant = "danger";
        if (!res.data["detail"]) {
          dispatch("toast", { obj: res.data, variant }, { root: true });
        } else {
          text = res.data.detail;
          title = "Errore durante il salvataggio!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      }

      commit("loading_status", false, { root: true });

      if (res.status == 201) return true;
      return false;
    },
    async stats({ commit, rootState, rootGetters }, payload) {
      await fetch(`${rootState.api}/pizzas/ingredients/stats/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        },
        body: JSON.stringify(payload)
      })
        .then(async response => {
          if ((await response.status) == 200) {
            commit("save_stats", await response.json());
          }
        })
        .catch(() => {});
    }
  }
};
