<template lang="pug">
  #home.container-cz
    b-modal(
      ref="modal"
      id="new-reward"
      :title="(form.id)?'Modifica' : 'Aggiungi'"
      centered
      :hide-footer="true"
    )
      validation-observer(ref="observer" v-slot="{ handleSubmit }")
        b-form(@submit.stop.prevent="handleSubmit(newPromotion)")
          b-row
            b-col(cols="6")
              form-input(
                label="Titolo" v-model="form.title" type="text"
                :model="form.title" name="title"
                :rules="{required: true}"
                invalid_text="Il campo è obbligatorio"
              )
            b-col(cols="6")
              form-input(
                label="Descrizione" v-model="form.description" type="text"
                :model="form.description" name="description"
                :rules="{required: true}"
                invalid_text="Il campo è obbligatorio"
              )
            b-col(cols="6")
              form-input(
                label="Testo del bottone" v-model="form.button_text" type="text"
                :model="form.button_text" name="button_text"
                :rules="{required: true}"
                invalid_text="Il campo è obbligatorio"
              )
            b-col(cols="6")
              form-input(
                label="Link del bottone" v-model="form.button_link" type="text"
                :model="form.button_link" name="button_link"
                :rules="{required: true, regex: url_re}"
                invalid_text="Il campo deve essere un URL che inizia con http://"
              )
            b-col(cols="6")
              form-input(
                label="Seleziona un tipo" v-model="form.promotion_type" type="select"
                :model="form.promotion_type" name="promotion_type"
                :rules="{required: true}"
                invalid_text="Devi selezionare un gruppo"
                :options="promotion_types"
              )
            b-col(cols="6")
              b-form-checkbox(
                v-model="form.is_public" switch
              ) Pubblico
            b-col(cols="12")
              label Foto
              b-form-file(
                v-model="form.photo"
                placeholder="Seleziona una foto"
                drop-placeholder="Rilascia il file qui..."
                @change="check_photo_size($event)"
                ref="file-input"
              )

          .buttons-center
            b-button(type="submit") Salva
            a(href="#" @click="toggle") Torna indietro

    b-container
      section.header-filters
        b-button(@click="new_promotion_modal")
          | Nuova promozione

    b-container
      div(v-for="section in promotion_sections" :key="section.type")
        h3
          b {{ section.text }}

        ul.promotions-list
          li(v-for="promotion in filter_promotions(section.type)")
            .d-flex
              .l.d-flex
                .arrows
                  .up(@click="change_position(promotion, -1)")
                    img(src="@/assets/arrow-sort.svg" alt="up")
                  .down(@click="change_position(promotion, 1)")
                    img(src="@/assets/arrow-sort.svg" alt="down")
                .image(:style="'background-image:url('+promotion.photo+')'")
              .r
                h1.title {{ promotion.title }}
                p.descr {{ promotion.description }}
                b-button(:href="promotion.button_link") {{ promotion.button_text }}
                .visible
                  .b(@click="change_status(promotion)")
                    img(
                      src="@/assets/available.svg"
                      alt="Public"
                      v-if="promotion.is_public"
                    )
                    img(src="@/assets/not-available.svg" alt="Not public" v-else)
                  b-badge(variant="success" v-if="promotion.is_public") visibile
                  b-badge(variant="danger" v-else) non visibile
                .buttons
                  img(
                    src="@/assets/edit.svg"
                    alt="Modifica"
                    title="Modifica"
                    @click="edit_promotion(promotion)"
                  )
                  img(
                    src="@/assets/trash-red.png"
                    alt="Elimina"
                    title="Elimina"
                    @click="delete_promotion(promotion.id)"
                  )
            a.link(:href="promotion.link") {{ promotion.link }}

</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Icon from "@/components/Icon.vue";
import FormInput from "@/components/FormInput.vue";

export default {
  name: "Home",
  components: {
    icon: Icon,
    "form-input": FormInput
  },
  data() {
    return {
      url_re: /^(http(s)?:\/\/)/,
      msg_settings: {
        title: "Azione irreversibile",
        size: "sm",
        buttonSize: "lg",
        okVariant: "danger",
        okTitle: "Sì",
        cancelTitle: "No",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true
      },
      form: { promotion_type: null },
      promotion_sections: [
        {
          text: "Slider sito",
          type: "slider"
        },
        {
          text: "Blocchi",
          type: "block"
        }
      ],
      promotion_types: [
        {
          value: "slider",
          text: "Slider"
        },
        {
          value: "block",
          text: "Block"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("promotions", ["promotions", "count"])
  },
  methods: {
    check_photo_size(file) {
      const mb_size = file.target.files[0].size / 1000000;
      if (mb_size > 1.0) {
        this.toast({
          text: "Seleziona un'immagine più piccola di 1MB",
          title: "Immagine troppo grande",
          variant: "danger"
        });
        this.$refs["file-input"].reset();
      }
    },
    toggle() {
      this.$refs.modal.toggle();
    },
    filter_promotions(promotion_type) {
      const promotions = this.promotions.filter(
        x => x.promotion_type == promotion_type
      );
      console.log(promotions);
      return promotions;
    },
    new_promotion_modal() {
      this.form = { promotion_type: null };
      this.toggle();
    },
    edit_promotion(item) {
      this.form = {
        id: item.id,
        title: item.title,
        description: item.description,
        promotion_type: item.promotion_type,
        button_text: item.button_text,
        button_link: item.button_link,
        is_public: item.is_public
      };
      this.toggle();
    },
    delete_promotion(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Sicuro di volere eliminare questa promozione?",
          this.msg_settings
        )
        .then(value => {
          if (value) {
            this.delete(id).then(response => {
              if (response) {
                this.get_promotions();
              }
            });
          }
        })
        .catch(() => {});
    },
    newPromotion() {
      if (!this.form.id) {
        this.create(this.form).then(response => {
          if (response) {
            this.toggle();
          }
        });
      } else {
        this.edit(this.form).then(response => {
          if (response) {
            this.get_promotions();
            this.toggle();
          }
        });
      }
    },
    change_position(promotion, how_many) {
      this.edit({
        id: promotion.id,
        position: promotion.position + how_many
      }).then(response => {
        if (response) {
          this.get_promotions();
        }
      });
    },
    change_status(promotion) {
      this.edit({ id: promotion.id, is_public: !promotion.is_public }).then(
        response => {
          if (response) {
            promotion.is_public = !promotion.is_public;
          }
        }
      );
    },
    ...mapActions("promotions", ["get_promotions", "edit", "create", "delete"]),
    ...mapActions(["toast"])
  },
  async mounted() {
    await this.get_promotions();
  }
};
</script>
