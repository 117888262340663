<template lang="pug">
  #orders.ordersrt.container-cz
    b-container
      section.header-filters
        div
          h4 Ordine manuale
          a.back(@click="window.history.back()") torna indietro
        #search-box
          b-form
            b-form-group(id="input-text-search")
              b-input(
                id="input-text-search"
                placeholder="ricerca"
                type="text"
                name="q"
                :value="$route.query.q"
              )
            b-button#search-btn(variant="light" type="submit")

          b-button(href="/a/orders/rt") Ordini RT

      section.header-filters
        b-button(href="/a/orders/new")
          | Nuovo ordine manuale

      section.filters
        h4 Filtra
        b-form(@change="filter()")
          b-row
            b-col
              b-form-checkbox(@change="filter()" v-model="filters.f_delivered" button button-variant="outline-secondary")
                | consegnato
            b-col
              form-input(
                label="Tutti gli ordini" v-model="filters.f_type" type="select"
                :model="filters.f_type" name="f_type"
                :options="[{text: 'Tutti gli ordini', value: null}, {text: 'Manuale', value: 'manual'}, {text: 'Web', value: 'web'}]"
              )
            b-col
              form-input(
                label="Momento di pagamento" v-model="filters.f_payment_on_delivery" type="select"
                :model="filters.f_payment_on_delivery" name="f_payment_on_delivery"
                :options="[{text: 'Metodo di pagamento', value: null}, {text: 'Consegna', value: 'y'}, {text: 'Online', value: 'n'}]"
              )
            b-col
              form-input(
                label="Tipo di consegna" v-model="filters.f_delivery_type" type="select"
                :model="filters.f_delivery_type" name="f_delivery_type"
                :options="[{text: 'Tipo di consegna', value: null}, {text: 'Asporto', value: 'take'}, {text: 'Domicilio', value: 'home'}]"
              )
            b-col
              form-input(
                label="Tipo di consegna" v-model="filters.f_delivery_type" type="select"
                :model="filters.f_delivery_type" name="f_delivery_type"
                :options="[{text: 'Tipo di consegna', value: null}, {text: 'Asporto', value: 'take'}, {text: 'Domicilio', value: 'home'}]"
              )

          .d-flex
            div
              label(for="f_from") Filtra da
              b-form-datepicker(
                @input="filter()"
                id="f_from"
                v-model="filters.f_from"
                locale="it-IT"
                start-weekday="1"
                placeholder="Data inizio range"
              )
            div
              label(for="f_to") a
              b-form-datepicker(
                @input="filter()"
                id="f_to"
                v-model="filters.f_to"
                locale="it-IT"
                start-weekday="1"
                placeholder="Data fine range"
              )
            div
              label Filtri data rapidi
              form-input(
                label="" v-model="filters.f_from" type="select"
                :model="filters.f_from" name="f_from"
                :options="[{text: 'Oggi', value: today}, {text: '7 giorni fa', value: sevenDays}, {text: '30 giorni fa', value: thirtyDays}]"
              )

    b-container
      orders-table(:items="orders" :is_busy="is_busy")

    #pagination
      b-pagination(
        v-model="current_page" :total-rows="count" per-page="24"
        first-number last-number @change="change_pag($event)"
      )

</template>

<script>
import { mapGetters, mapActions } from "vuex";

import OrdersTable from "@/components/OrdersTable.vue";
import FormInput from "@/components/FormInput.vue";

export default {
  name: "Orders",
  components: {
    "orders-table": OrdersTable,
    "form-input": FormInput
  },
  data() {
    return {
      is_busy: true,
      current_page: 1,
      page_num: 1,
      filters: {
        f_type: null,
        f_delivered: null,
        f_payment_on_delivery: null,
        f_delivery_type: null,
        f_from: null,
        f_to: null
      }
    };
  },
  computed: {
    ...mapGetters("orders", ["orders", "count"]),
    today() {
      return this.$moment().format("YYYY-MM-DD");
    },
    sevenDays() {
      return this.$moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD");
    },
    thirtyDays() {
      return this.$moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD");
    }
  },
  methods: {
    change_pag(pagenum) {
      this.current_page = pagenum;
      window.location.href = this.$route.path + "?p=" + this.current_page;
    },
    filter() {
      let url = this.$route.path;
      if (this.current_page > 1) {
        url += `?p=${this.current_page}&`;
      } else {
        url += `?`;
      }

      window.setTimeout(() => {
        console.log(JSON.stringify(this.filters));
        for (const filter of Object.keys(this.filters)) {
          if (this.filters[filter]) {
            url += `${filter}=${this.filters[filter]}&`;
          }
        }

        window.location.href = url;
      }, 400);
    },
    ...mapActions("orders", ["get_orders"])
  },
  async mounted() {
    const query = this.$route.query;
    if (query["f_delivered"]) this.filters.f_delivered = true;
    if (query["f_type"]) this.filters.f_type = query.f_type;
    if (query["f_payment_on_delivery"])
      this.filters.f_payment_on_delivery = query.f_payment_on_delivery;
    if (query["f_delivery_type"])
      this.filters.f_delivery_type = query.f_delivery_type;
    if (query["f_from"]) this.filters.f_from = query.f_from;
    if (query["f_to"]) this.filters.f_to = query.f_to;

    if (query["q"]) {
      await this.get_orders({
        q: query.q,
        filters: this.filters
      });
    } else {
      this.page_num = parseInt(this.$route.query["p"]) || 1;

      await this.get_orders({
        p: this.page_num,
        filters: this.filters
      });

      setTimeout(() => {
        this.$set(this.$data, "current_page", this.page_num);
      }, 1000);
    }
    this.is_busy = false;
  }
};
</script>
