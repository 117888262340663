<template lang="pug">
  .ordersrt.container-cz
    b-container
      section.header-filters
        a#back-to-orders(href="/a/orders") Torna a ordini
        #search-box
          b-form
            b-form-group(id="input-text-search")
              b-input(
                id="input-text-search"
                placeholder="ricerca"
                type="text"
                name="q"
                :value="$route.query.q"
              )
            b-button#search-btn(variant="light" type="submit")

    b-container
      orders-table(:items="orders" :is_busy="is_busy")

    #pagination
      b-pagination(
        v-model="current_page" :total-rows="count" per-page="24"
        first-number last-number @change="change_pag($event)"
      )

</template>

<script>
import { mapGetters, mapActions } from "vuex";

import OrdersTable from "@/components/OrdersTable.vue";

export default {
  name: "OrdersRT",
  components: {
    "orders-table": OrdersTable,
  },
  data() {
    return {
      is_busy: true,
      current_page: 1,
      page_num: 1,
      current_count: 0,
    };
  },
  computed: {
    ...mapGetters("orders", ["orders", "count"]),
  },
  methods: {
    change_pag(pagenum) {
      this.current_page = pagenum;
      window.location.href = this.$route.path + "?p=" + this.current_page;
    },
    async polling_orders() {
      if (this.$route.query["q"]) {
        await this.get_orders({ q: this.$route.query.q });
      } else {
        this.page_num = parseInt(this.$route.query["p"]) || 1;

        await this.get_orders({ p: this.page_num });

        setTimeout(() => {
          this.$set(this.$data, "current_page", this.page_num);
        }, 1000);
        if (this.current_count != this.count) {
          this.play();
          this.current_count = this.count;
        }
      }
    },
    create_orders_table() {
      this.polling_orders();
      this.current_count = this.count;
      setInterval(() => {
        this.polling_orders();
      }, 30000);
    },
    play() {
      const s = new Audio("/alert.wav");
      s.play();
    },
    ...mapActions("orders", ["get_orders"]),
  },
  async created() {
    this.create_orders_table();
    this.is_busy = false;
  },
  beforeDestroy() {
    clearInterval(this.orders);
  },
};
</script>
