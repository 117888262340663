const ADMIN_ROLE = 1;

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem("access_token") || false,
    user: undefined
  },
  getters: {
    access_token: state => {
      return state.token;
    },
    is_logged: state => {
      return state.token != false;
    },
    get_me: state => {
      return state.user;
    },
    am_i_an_admin: state => {
      if (state.user) return state.user.role == ADMIN_ROLE;

      return false;
    }
  },
  mutations: {
    save_access_token: (state, value) => {
      localStorage.setItem("access_token", value);
      state.token = value;
    },
    delete_access_token: state => {
      // Remove access_token and credentials from the browser data
      localStorage.removeItem("access_token");
      state.token = false;
      localStorage.removeItem("login");
      state.user = undefined;
    },
    save_user_info: (state, data) => {
      // Save user's informations from the endpoint `/me`, like its name, phone
      // and role
      state.user = data;
    }
  },
  actions: {
    check_access_token({ commit }, data) {
      // Check if response of login (or signup) has the access token. If yes,
      // save it and also save the login credentials
      if (data.res["access_token"]) {
        // save access_token
        commit("save_access_token", data.res.access_token);

        // save login credentials for later
        localStorage.setItem("login", JSON.stringify(data.credentials));
      }
    },
    async keep_access({ commit, getters, dispatch, rootState }) {
      // Check if a logged user has a valid access_token. It could be expired
      // so, in that case, make a new login with saved login credentials in the
      // LocalStorage. If there's nothing there, continue with the logout!
      // Returns the `access_token`
      let status = true;

      await fetch(`${rootState.api}/profiles/me/`, {
        headers: { Authorization: `Bearer ${getters.access_token}` }
      }).then(async response => {
        if ((await response.status) != 200) {
          status = false;
        } else {
          commit("save_user_info", await response.json());
        }
      });

      // if `status` is false, it means that access_token is expired
      if (!getters.am_i_an_admin) {
        commit("delete_access_token");
        return null;
      } else if (!status) {
        const credentials = localStorage.getItem("login");
        // if it doesn't have login credentials saved, how it could be make a
        // login? So remove access token and continue
        // Also delete access_token if the user is not (or not more) an admin
        if (!credentials || !getters.am_i_an_admin) {
          commit("delete_access_token");
          return null;
        } else {
          // make a new login with the saved credentials and, if response has a
          // `detail` key, it means that login went wrong, so remove credentials
          // and access token
          await dispatch("login", JSON.parse(credentials)).then(
            async response => {
              if (response["detail"]) {
                commit("delete_access_token");
                return null;
              }
            }
          );
        }
      }

      return getters.access_token;
    },
    async login({ commit, getters, dispatch, rootState }, credentials) {
      // Make the login and then save the credentials. It uses `rootState`
      // because it needs the root store module. It uses `dispatch` because it
      // needs to call a different action.
      // It returns the response in JSON format o status in case of response
      // error
      commit("loading_status", true, { root: true });
      let res = { status: 0, data: null };
      await fetch(`${rootState.api}/auth/login/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials)
      })
        .then(async response => {
          res.data = await response.json();
          res.status = await response.status;
          dispatch("check_access_token", { res: res.data, credentials });
          if (res.status == 200) {
            // This is usefull if the login is called by `keepAccess`
            commit("save_user_info", res.data);
          } else {
            commit("delete_access_token");
          }
        })
        .catch(e => {
          res.status = e.status;
        });

      commit("loading_status", false, { root: true });

      let text, title, variant;
      variant = "danger";

      if (res.status != 200) {
        if (!res.data["detail"]) {
          dispatch("toast", { obj: res.data, variant }, { root: true });
        } else {
          text = res.data.detail;
          title = "Errore durante il login!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      } else {
        // Get the profile of the user to check his role
        await dispatch("keep_access");

        if (getters.am_i_an_admin) {
          variant = "success";
          text = "Sarai presto reindirizzato!";
          title = "Login effettuato!";
          dispatch("toast", { text, title, variant }, { root: true });
          return true;
        } else {
          text = "Chiedi ad un amministratore se il problema persiste";
          title = "Errore durante il login!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      }

      return false;
    },
    async change_password({ commit, dispatch, getters, rootState }, payload) {
      // Save new password
      commit("loading_status", true, { root: true });

      let res = { status: 0, data: {} };

      await fetch(`${rootState.api}/auth/password/change/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getters.access_token}`
        },
        body: JSON.stringify(payload)
      })
        .then(async response => {
          res.data = await response.json();
          res.status = await response.status;
        })
        .catch(e => {
          res.status = e.status;
        });

      let text = "Modifiche salvate con successo",
        title = "Password salvata!",
        variant = "success";
      if (res.status == 200) {
        dispatch("toast", { text, title, variant }, { root: true });
      } else {
        if (!res.data["detail"]) {
          dispatch("toast", { obj: res.data, variant }, { root: true });
        } else {
          text = res.data.detail;
          title = "Errore durante il login!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      }

      commit("loading_status", false, { root: true });

      return res.status
    },
    logout({ commit }) {
      commit("delete_access_token");
    }
  }
};
