<template lang="pug">
  #orders-new.container-cz
    b-form
      validation-observer(ref="observer" v-slot="{ handleSubmit }")
        b-form(@submit.stop.prevent="handleSubmit(create)")
          h3 Dati cliente
          b-row
            b-col(md="4")
              form-input(
                label="nome e cognome" v-model="form.manual_data.name" type="text"
                :model="form.manual_data.name" name="manual_data_name"
                :rules="{required: true}"
                invalid_text="Campo obbligatorio"
              )
            b-col(md="4")
              form-input(
                label="telefono (opzionale)" v-model="form.manual_data.phone" type="text"
                :model="form.manual_data.phone" name="manual_data_phone"
              )
          b-row
            b-col(md="4")
              vue-google-autocomplete(
                id="map"
                :classname="map_class"
                placeholder="indirizzo"
                @placechanged="get_address_data"
                v-if="!form.address.id"
              )
              vue-google-autocomplete(
                id="map"
                :classname="map_class"
                :placeholder="serialize_address(form.address)"
                @placechanged="get_address_data"
                v-else
              )
              #inputaddressfeedback.invalid-feedback(
                v-if="map_class.indexOf('is-invalid') > -1"
              ) L'indirizzo dev'essere completo di via, numero civico e città
            b-col(md="4")
              form-input(
                label="email (opzionale)" v-model="form.manual_data.email" type="text"
                :model="form.manual_data.email" name="manual_data_email"
              )
            b-col(md="4")
              form-input(
                label="Seleziona uno stato" v-model="form.status" type="select"
                :model="form.status" name="status"
                :rules="{required: true}"
                invalid_text="Devi selezionare uno stato"
                :options="statuses"
              )

          b-row
            b-col(md="8").delivery
              .d-flex
                b-form-radio(
                  v-model="form.delivery"
                  name="delivery"
                  value="take"
                  button
                  button-variant="outline-secondary"
                )
                  img.radio-img.w(src="@/assets/store-w.png" alt="store")
                  img.radio-img.b(src="@/assets/store.png" alt="store")
                  div
                    h4 Ritiro in pizzeria
                    p Via Umberto I, 109, Catania

                b-form-radio(
                  v-model="form.delivery"
                  name="delivery"
                  value="home"
                  button
                  button-variant="outline-secondary"
                )
                  img.radio-img.w(src="@/assets/home-w.png" alt="home")
                  img.radio-img.b(src="@/assets/home.png" alt="home")
                  div
                    h4 Domicilio
                    p Specificare l'indirizzo

            b-col(md="4")
              label Orario di ritiro/consegna
              form-input(
                label="" v-model="form.delivery_time" type="time"
                :model="form.delivery_time" name="delivery_time"
              )
              b-button(variant="secondary" @click="set_delivery_time_default")
                | Prima possibile

          .sep

          h3 Pizze
          b-row
            b-col(md="6")
              ul.pizzas
                li
                  h5 Conzata
                  .qta.horizontal.d-flex
                    b-button(variant="secondary" @click="chg_qta(-1, 0)") -
                    b-form-input(
                      type="number" min="0" max="99" name="quantity"
                      v-model="form.pizzas[0]" @change="update_pizza(0)"
                    )
                    b-button(variant="secondary" @click="chg_qta(1, 0)") +
                li
                  h5 Margherita
                  span.disabled(v-if="!margherita['id']") Prima vai su 
                    a(href="/a/settings") qui 
                    | e definisci una pizza margherita!
                  .qta.horizontal.d-flex
                    b-button(
                      variant="secondary"
                      @click="chg_qta(-1, 1)"
                      :disabled="!margherita['id']"
                    ) -
                    b-form-input(
                      type="number" min="0" max="99" name="quantity"
                      v-model="form.pizzas[1]" @change="update_pizza(1)"
                      :disabled="!margherita['id']"
                    )
                    b-button(
                      variant="secondary"
                      @click="chg_qta(1, 1)"
                      :disabled="!margherita['id']"
                    ) +
            b-col(md="6")
              form-input(
                label="" v-model="form.note" type="textarea"
                :model="form.note" name="note"
              )

          .sep
          h3 Extra
          b-row
            extfood-cart(
              :group="group"
              :items="extfood_items"
              v-for="group in extfood_groups"
              :key="group.id"
            )

          .center
            h3 Riepilogo ordine
            ul
              li(v-if="form.pizzas[0] > 0")
                | Pizza conzata x{{ form.pizzas[0] }}
                span - €{{ float_precision(conzata, form.pizzas[0]) }}
              li(v-if="form.pizzas[1] > 0")
                | Margherita x{{ form.pizzas[1] }}
                span - €{{ float_precision(margherita, form.pizzas[1]) }}
              li(v-for="item in extfoods" :key="item.id")
                | {{ item.name }} x{{ item.qty }}
                span - €{{ float_precision(item.price, item.qty) }}
            h4 Totale €{{ total }}

            b-button(type="submit") Salva

</template>

<script>
import { mapGetters, mapActions } from "vuex";

import FormInput from "@/components/FormInput.vue";
import ExtfoodCart from "@/components/ExtfoodCart.vue";

import VueGoogleAutocomplete from "vue-google-autocomplete";

const moment = require("moment");

export default {
  name: "OrdersNew",
  components: {
    "form-input": FormInput,
    "extfood-cart": ExtfoodCart,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      form: {
        manual_data: {},
        status: null,
        pizzas: [0, 0],
        address: {},
      },
      map_class: "form-control",
      statuses: [
        { value: null, text: "Seleziona uno stato" },
        { value: 0, text: "Ricevuto" },
        { value: 1, text: "Cancellato" },
        { value: 2, text: "In preparazione" },
        { value: 3, text: "In consegna" },
        { value: 4, text: "Consegnato" },
        { value: 5, text: "Completato" },
      ],
    };
  },
  computed: {
    ...mapGetters("extfood", {
      extfood_items: "get_grouped_items",
      extfood_groups: "get_groups",
    }),
    ...mapGetters("pizzas", ["margherita"]),
    ...mapGetters("cart", {
      extfoods: "extfoods",
      items_pizzas: "pizzas",
      total: "total",
    }),
    ...mapGetters("orders", ["conzata", "orders_settings"]),
  },
  mounted() {
    this.$store.dispatch("extfood/get_groups");
    this.$store.dispatch("orders/get_settings").then((settings) => {
      if (settings.margherita) {
        this.pizza_find({ url: settings.margherita, type: "margherita" });
      }
    });

    const now = moment();
    this.$set(this.form, "delivery_time", {
      HH: now.format("HH"),
      mm: now.format("mm"),
    });
  },
  methods: {
    get_address_data(data, result, id) {
      console.log(id);
      this.form.address.street = `${data.route} ${data.street_number ?? ""}`;
      this.form.address.city = data.locality;
      if (result.address_components[4].short_name.length == 2)
        this.form.address.province = result.address_components[4].short_name;
      this.form.address.postcode = data.postal_code;
      const fa = this.form.address;
      if (!(fa.street && fa.city && fa.province && fa.postcode)) {
        this.$set(this.$data, "map_class", "form-control is-invalid");
      } else {
        this.$set(this.$data, "map_class", "form-control");
      }
    },
    set_delivery_time_default() {
      this.$set(this.form, "delivery_time", {
        HH: "18",
        mm: "20",
      });
    },
    float_precision(price, qty) {
      if (typeof price == "object") {
        price = parseFloat(price.prices[0].price);
      }

      const total = price * qty;
      return total.toFixed(2);
    },
    chg_qta(value, index) {
      let qty = parseInt(this.form.pizzas[index]);
      if (value + qty >= 0) {
        this.$set(this.form.pizzas, index, value + qty);
      }

      this.update_pizza(index);
    },
    update_pizza(index) {
      let total = 0;
      if (index == 0) {
        total = this.form.pizzas[0] * parseFloat(this.conzata.prices[0].price);
      } else {
        total =
          this.form.pizzas[1] * parseFloat(this.margherita.prices[0].price);
      }

      this.add_item({
        type: "pizza",
        item: {
          index,
          total,
        },
      });
    },
    check_delivery(value) {
      const hour = moment(value, "HH:mm").format("HH");
      const minutes = moment(value, "HH:mm").format("mm");

      if (hour == 18 && minutes >= 20) return true;
      else if (hour >= 19 && hour <= 23) return true;

      return false;
    },
    create() {
      const payload = { ...this.form };
      if (payload.delivery == "take") delete payload.address;

      delete payload.delivery_time; // Remove the object because we'll add the string later
      let delivery_time_is_good = false;

      if (this.form.delivery_time) {
        payload.delivery_time = `${this.form.delivery_time.HH}:${this.form.delivery_time.mm}`;
        delivery_time_is_good = this.check_delivery(payload.delivery_time);
      }

      if (!this.form.delivery_time || !delivery_time_is_good) {
        this.toast({
          title: "Orario di ritiro/consegna",
          text: "L'orario deve essere compreso tra le 18:20 e le 23:59",
          variant: "danger",
        });
        return;
      }

      payload.pizzas = [];
      payload.extras = [];

      if (this.form.pizzas[0]) {
        payload.pizzas.push({
          pizza: this.conzata.url,
          quantity: this.form.pizzas[0],
          size: 1,
          dough: 1,
        });
      }

      if (this.form.pizzas[1]) {
        payload.pizzas.push({
          pizza: this.margherita.url,
          quantity: this.form.pizzas[1],
          size: 1,
          dough: 1,
        });
      }

      for (const extra of this.extfoods) {
        payload.extras.push({
          item: extra.id,
          quantity: extra.qty,
        });
      }

      this.create_order(payload).then((response) => {
        if (response) {
          setTimeout(() => {
            window.location.href = "/a/orders";
          }, 2000);
        }
      });
    },
    ...mapActions(["toast"]),
    ...mapActions("pizzas", { pizza_find: "find" }),
    ...mapActions("cart", ["add_item"]),
    ...mapActions("orders", ["create_order"]),
  },
};
</script>
