export default {
  namespaced: true,
  state: {
    items: { pizzas: [0, 0], extfood: [] },
    total: 0
  },
  getters: {
    extfoods: state => {
      return state.items.extfood;
    },
    pizzas: state => {
      return state.items.pizzas;
    },
    total: state => {
      return state.total.toFixed(2);
    }
  },
  mutations: {
    save_new_item_extfood: (state, item) => {
      if (!state.items) {
        state.items = {};
        state.items["extfood"] = [];
      } else if (!state.items["extfood"]) {
        state.items["extfood"] = [];
      }

      state.items.extfood.push(item);
    },
    remove_item_extfood: (state, food_id) => {
      let index_to_delete = -1;
      for (const i in state.items.extfood) {
        if (state.items.extfood[i].id == food_id) {
          index_to_delete = i;
          break;
        }
      }

      if (index_to_delete >= 0) state.items.extfood.splice(index_to_delete, 1);
    },
    save_new_item_pizza: (state, data) => {
      state.items.pizzas[data.index] = data.total;
    },
    save_total: state => {
      let total = state.items.pizzas.reduce((a, b) => a + b);
      if (state.items.extfood.length > 0) {
        total += state.items.extfood
          .map(x => x.qty * x.price)
          .reduce((a, b) => a + b);
      }
      state.total = total;
    }
  },
  actions: {
    add_item({ commit }, data) {
      commit(`save_new_item_${data.type}`, data.item);
      commit("save_total");
    },
    remove_item({ commit }, data) {
      commit(`remove_item_${data.type}`, data.item);
      commit("save_total");
    },
    make_total({ commit }) {
      commit("save_total");
    }
  }
};
