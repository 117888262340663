const moment = require("moment");

export default {
  namespaced: true,
  state: {
    users_list: [],
    count: 0,
    stats: [],
    couriers_stats: [],
  },
  getters: {
    users: (state) => {
      return state.users_list;
    },
    count: (state) => {
      return state.count;
    },
    stats: (state) => {
      const data = {
        labels: state.stats.map((i) => moment(i.x).format("DD/MM/YYYY")),
        datasets: [
          {
            label: "Utenti",
            data: [],
            backgroundColor: "#eed2d1",
            borderColor: "#eed2d1",
          },
        ],
      };

      state.stats.forEach((i) => {
        data.datasets[0].data.push(i.y);
      });

      return data;
    },
    couriers_stats: (state) => {
      const data = {
        labels: state.couriers_stats.map((i) =>
          moment(i.x).format("DD/MM/YYYY")
        ),
        datasets: [
          {
            label: "Corrieri",
            data: [],
            backgroundColor: "#eed2d1",
            borderColor: "#eed2d1",
          },
        ],
      };

      state.couriers_stats.forEach((i) => {
        data.datasets[0].data.push(i.y);
      });

      return data;
    },
  },
  mutations: {
    save_users: (state, data) => {
      state.users_list = data["results"];
      state.count = data["count"];
    },
    save_stats: (state, data) => {
      state.stats = data;
    },
    save_couriers_stats: (state, data) => {
      state.couriers_stats = data;
    },
  },
  actions: {
    async get_users({ commit, rootState, rootGetters }, data) {
      let page = 1;
      let limit = 24;
      let query = "";

      if (data.p) {
        page = data.p;
      } else if (data.q) {
        limit = 1000;
        query = data.q;
      }

      let url = `${rootState.api}/profiles/?limit=${limit}&offset=${
        (page - 1) * limit
      }`;

      if (data["filters"]) {
        for (const filter of Object.keys(data.filters)) {
          if (data.filters[filter]) {
            url += `&${filter}`;
          }
        }
      }

      await fetch(url, {
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
      }).then(async (response) => {
        const items = await response.json();
        if (query == "") {
          commit("save_users", items);
        } else {
          // TODO make a better search user
          query = query.toLowerCase();
          const items_filtered = { count: 0, results: [] };
          for (const item of items.results) {
            const string_to_check =
              `${item.name}-${item.username}-${item.email}`.toLowerCase();
            if (string_to_check.indexOf(query) > -1) {
              items_filtered.results.push(item);
            }
          }
          commit("save_users", items_filtered);
        }
      });
    },
    async edit({ commit, dispatch, rootState, rootGetters }, item) {
      commit("loading_status", true, { root: true });

      let res = { status: 0, data: {} };

      await fetch(`${rootState.api}/profiles/${item.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify({
          name: item.name,
          cellular: item.cellular,
          phone: item.phone,
          role: item.role,
        }),
      })
        .then(async (response) => {
          res.data = await response.json();
          res.status = response.status;
        })
        .catch((e) => {
          res.data = { detail: "Errore generico, non sappiamo dove" };
          res.status = e.status;
        });

      let text = "Utente modificato con successo",
        title = "Utente salvato!",
        variant = "success";
      if (res.status == 200) {
        dispatch("toast", { text, title, variant }, { root: true });
      } else {
        variant = "danger";
        if (!res.data["detail"]) {
          dispatch("toast", { obj: res.data, variant }, { root: true });
        } else {
          text = res.data["detail"];
          title = "Errore durante il salvataggio!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      }

      commit("loading_status", false, { root: true });

      if (res.status == 200) return true;
      return false;
    },
    async alter_active({ commit, rootState, rootGetters }, data) {
      // `data` is { id: <id>, is_active: ..}
      let to_return = false;
      commit("loading_status", true, { root: true });

      await fetch(`${rootState.api}/accounts/active/${data.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify({ is_active: data.status }),
      })
        .then(async (response) => {
          if (response.status == 200) to_return = true;
        })
        .catch(() => {});

      commit("loading_status", false, { root: true });
      return to_return;
    },
    async notify({ commit, dispatch, rootState, rootGetters }, data) {
      let r = { data: {}, status: 400 };
      commit("loading_status", true, { root: true });

      const payload = {
        title: data.form.title,
        body: data.form.body,
      };

      let url = `${rootState.api}/notifications/send-`;

      if (data.users == "__all__") {
        url += "all/";
      } else {
        payload["usernames"] = data.users;
        url += "bulk/";
      }
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          r.data = await response.json();
          r.status = response.status;
        })
        .catch((e) => {
          r.data = { detail: "Errore generico, non sappiamo dove" };
          r.status = e.status;
        });

      let text = "Notifica inviata con sucesso",
        title = "Notifica inviata!",
        variant = "success";
      if (r.status == 200) {
        dispatch("toast", { text, title, variant }, { root: true });
      } else {
        variant = "danger";
        if (!r.data["detail"]) {
          dispatch("toast", { obj: r.data, variant }, { root: true });
        } else {
          text = r.data["detail"];
          title = "Errore durante l'invio!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      }

      commit("loading_status", false, { root: true });

      if (r.status == 200) return true;
      return false;
    },
    async stats({ commit, rootState, rootGetters }, payload) {
      await fetch(`${rootState.api}/accounts/stats/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          if (response.status == 200) {
            commit("save_stats", await response.json());
          }
        })
        .catch(() => {});
    },
    async couriers_stats({ commit, rootState, rootGetters }, payload) {
      await fetch(`${rootState.api}/accounts/stats/couriers/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          if (response.status == 200) {
            commit("save_couriers_stats", await response.json());
          }
        })
        .catch(() => {});
    },
  },
};
