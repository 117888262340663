<template lang="pug">
  #app(:class="{c100 : !sidebar}")
    #big-loading(v-if="loading")
      b-overlay(:show="true" spinner-large)
    cz-header(:active="active" :inner_active="inner_active" :sidebar="sidebar" :title="title")
    main
      router-view
</template>

<script>
import Header from "@/components/Header.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    "cz-header": Header
  },
  data() {
    return {
      title: "Dashboard Gestionale",
      active: 0,
      inner_active: 0,
      sidebar: false
    };
  },
  computed: {
    ...mapGetters(["loading"]),
    ...mapGetters("auth", ["is_logged"])
  },
  mounted() {
    if (this.$route.name != "Login") {
      this.sidebar = true;
    }

    if (this.is_logged) {
      this.$store.dispatch("auth/keep_access").then(access_token => {
        if (access_token != null && !this.sidebar) {
          window.location.href = "/a/home";
        } else if (access_token == null) {
          window.location.href = "/";
        }
      });
    }

    switch (this.$route.name) {
      case "Home":
        this.active = 1;
        break;
      case "Users":
        this.active = 2;
        break;
      case "Ingredients":
        this.active = 3;
        break;
      case "Doughs":
        this.active = 10;
        break;
      case "Pizza":
        this.active = 4;
        break;
      case "Extfoods":
        this.active = 11;
        break;
      case "ExtfoodsGroups":
        this.active = 11;
        this.inner_active = 111;
        break;
      case "Statistics":
        this.active = 5;
        break;
      case "Orders":
        this.active = 7;
        break;
      case "OrdersRT":
        this.active = 7;
        this.inner_active = 71;
        break;
      case "Rewards":
        this.active = 8;
        break;
      case "Settings":
        this.active = 9;
        break;
    }
  }
};
</script>

<style lang="sass">
@import 'node_modules/bootstrap/scss/bootstrap.scss'
@import 'node_modules/bootstrap-vue/src/index.scss'
@import "./sass/main.sass"
</style>
