import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { BootstrapVue } from "bootstrap-vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, min, confirmed, regex } from "vee-validate/dist/rules";

extend("required", required);
extend("min", min);
extend("confirmed", confirmed);
extend("regex", regex);

Vue.config.productionTip = false;

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.use(BootstrapVue);
Vue.use(require("vue-moment"));

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_RATE,
  environment: process.env.VUE_APP_SENTRY_ENV
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
