<template lang="pug">
  b-form-group
    validation-provider(
      :rules="rules" v-slot="validationContext" :vid="name"
    )
      label(v-if="local_type == 'select' && multiple") {{ label }}
      b-form-select(
        v-if="local_type == 'select' && !multiple"
        @input="handleChange" :placeholder="label.toLowerCase()"
        :state="getValidationState(validationContext)"
        :aria-describedby="'input'+name+'feedback'" :value="model"
        :options="options"
      )
      b-form-select(
        v-else-if="local_type == 'select' && multiple"
        @input="handleChange"
        :state="getValidationState(validationContext)"
        :aria-describedby="'input'+name+'feedback'" :value="model"
        :options="options" multiple
      )
      b-form-textarea(
        v-else-if="local_type == 'textarea'"
        @input="handleChange" :placeholder="label.toLowerCase()"
        :state="getValidationState(validationContext)"
        :aria-describedby="'input'+name+'feedback'" :value="model"
        rows="3"
        max-rows="3"
      )
      vue-timepicker(
        class="form-control"
        v-else-if="local_type == 'time'"
        close-on-complete
        @input="handleChange"
        :value="model"
      )
      b-input(
        v-else
        @input="handleChange" :placeholder="label.toLowerCase()" :type="local_type"
        step="0.01"
        :state="getValidationState(validationContext)"
        :aria-describedby="'input'+name+'feedback'" :value="model"
      )
      span.password(
        v-if="type == 'password'"
        :class="{'_p2': local_type == 'text'}"
        @click="passwordEye"
      )
      b-form-invalid-feedback(:id="'input'+name+'feedback'")
        | {{ invalid_text }}
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  name: "FormInput",
  props: [
    "label",
    "rules",
    "model",
    "name",
    "invalid_text",
    "type",
    "options",
    "multiple"
  ],
  components: {
    VueTimepicker
  },
  data() {
    return {
      local_type: this.$props.type
    };
  },
  created() {
    if (this.type == "select" && !this.multiple) {
      if (this.options[0].value != null) {
        this.options.unshift({
          value: null,
          text: this.label
        });
      }
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    handleChange(event) {
      this.$emit("input", event);
    },
    passwordEye() {
      this.local_type = this.local_type == "password" ? "text" : "password";
    }
  }
};
</script>
