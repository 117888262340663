export default {
  namespaced: true,
  state: {
    items: [],
    groups: [],
    extfoods: [],
    count: 0,
    count_groups: 0
  },
  getters: {
    get_grouped_items: state => {
      const groups = state.groups.map(x => x.id);

      let data = {};
      for (const i of groups) {
        data[i] = state.items.filter(x => x.group == i);
      }

      return data;
    },
    get_groups: state => {
      return state.groups;
    },
    get_groups_for_select: state => {
      return state.groups
        .sort(x => x.position)
        .map(group => {
          return {
            value: group.id,
            text: group.name
          };
        });
    },
    extfoods: state => {
      return state.extfoods;
    },
    count: state => {
      return state.count;
    },
    groups: state => {
      return state.groups;
    },
    count_groups: state => {
      return state.count_groups;
    },
    group_name: state => group_id => {
      return state.groups.filter(x => x.id == group_id)[0].name;
    }
  },
  mutations: {
    save_items: (state, data) => {
      state.items = data["results"];
    },
    save_groups: (state, data) => {
      state.groups = data["results"];
      state.count_groups = data["count"];
    },
    save_extfoods: (state, data) => {
      state.extfoods = data["results"];
      state.count = data["count"];
    },
    insert_extfood: (state, item) => {
      state.extfoods.push(item);
      state.count++;
    },
    insert_group: (state, item) => {
      state.groups.push(item);
      state.count_groups++;
    }
  },
  actions: {
    async get_groups({ commit, rootState }, data) {
      let page = 1;
      let limit = 24;
      let query = "";

      if (!data) {
        limit = 1000;
      } else if (data.p) {
        page = data.p;
      } else if (data.q) {
        limit = 1000;
        query = data.q;
      }

      let res;
      await fetch(
        `${rootState.api}/extfood/groups/?limit=${limit}&offset=${(page - 1) *
          limit}`
      ).then(async response => {
        const items = await response.json();
        if (query == "") {
          commit("save_groups", items);
          res = items;
        } else {
          query = query.toLowerCase();
          const items_filtered = { count: 0, results: [] };
          for (const item of items.results) {
            if (item.name.toLowerCase().indexOf(query) > -1) {
              items_filtered.results.push(item);
            }
          }
          commit("save_groups", items_filtered);
          res = items_filtered;
        }
      });

      await fetch(`${rootState.api}/extfood/items/?limit=1000`).then(
        async response => {
          commit("save_items", await response.json());
        }
      );

      return res.results;
    },
    async get_extfoods({ commit, rootState }, data) {
      let page = 1;
      let limit = 24;
      let query = "";

      if (data.p) {
        page = data.p;
      } else if (data.q) {
        limit = 1000;
        query = data.q;
      }

      await fetch(
        `${rootState.api}/extfood/items/?limit=${limit}&offset=${(page - 1) *
          limit}`
      ).then(async response => {
        const items = await response.json();
        if (query == "") {
          commit("save_extfoods", items);
        } else {
          query = query.toLowerCase();
          const items_filtered = { count: 0, results: [] };
          for (const item of items.results) {
            if (item.name.toLowerCase().indexOf(query) > -1) {
              items_filtered.results.push(item);
            }
          }
          commit("save_extfoods", items_filtered);
        }
      });
    },
    async edit_group({ dispatch }, data) {
      data.type = "groups";
      return dispatch("edit", data);
    },
    async edit({ commit, dispatch, rootState, rootGetters }, item) {
      commit("loading_status", true, { root: true });

      let res = { status: 0, data: {} };

      let type = "items";
      if (item.type) {
        type = item.type;
        delete item.type;
      }

      await fetch(`${rootState.api}/extfood/${type}/${item.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        },
        body: JSON.stringify(item)
      })
        .then(async response => {
          res.data = await response.json();
          res.status = await response.status;
        })
        .catch(e => {
          res.data = { detail: "Errore generico, non sappiamo dove" };
          res.status = e.status;
        });

      let text = "Extra modificato con successo",
        title = "Extra salvato!",
        variant = "success";
      if (res.status == 200) {
        dispatch("toast", { text, title, variant }, { root: true });
      } else {
        variant = "danger";
        if (!res.data["detail"]) {
          dispatch("toast", { obj: res.data, variant }, { root: true });
        } else {
          text = res.data.detail;
          title = "Errore durante il salvataggio!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      }

      commit("loading_status", false, { root: true });

      if (res.status == 200) return true;
      return false;
    },
    async delete_group({ dispatch }, data) {
      data.type = "groups";
      return dispatch("delete", data);
    },
    async delete({ commit, dispatch, rootState, rootGetters }, item) {
      commit("loading_status", true, { root: true });

      let res = { status: 0, data: {} };

      let type = "items";
      if (item.type) {
        type = item.type;
        delete item.type;
      }

      await fetch(`${rootState.api}/extfood/${type}/${item.id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        }
      })
        .then(async response => {
          res.status = await response.status;
        })
        .catch(e => {
          res.status = e.status;
          res.data = { detail: e.status };
        });

      let text = "Extra eliminato con successo",
        title = "Extra eliminato!",
        variant = "success";
      if (res.status == 204) {
        dispatch("toast", { text, title, variant }, { root: true });
      } else {
        variant = "danger";

        if (!res.data["detail"]) {
          dispatch("toast", { obj: res.data, variant }, { root: true });
        } else {
          text = res.data.detail;
          title = "Errore durante il salvataggio!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      }

      commit("loading_status", false, { root: true });

      if (res.status == 204) return true;
      return false;
    },
    async create_group({ dispatch }, data) {
      data.type = "groups";
      return dispatch("create", data);
    },
    async create({ commit, dispatch, rootState, rootGetters }, form) {
      commit("loading_status", true, { root: true });

      let res = { status: 0, data: {} };
      let type = "items";
      if (form.type) {
        type = form.type;
        delete form.type;
      }

      await fetch(`${rootState.api}/extfood/${type}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        },
        body: JSON.stringify(form)
      })
        .then(async response => {
          res.data = await response.json();
          res.status = await response.status;
        })
        .catch(e => {
          res.status = e.status;
        });

      let text = "Extra inserito con successo",
        title = "Extra salvato!",
        variant = "success";
      if (res.status == 201) {
        dispatch("toast", { text, title, variant }, { root: true });

        if (type == "items") commit("insert_extfood", res.data);
        else commit("insert_group", res.data);
      } else {
        variant = "danger";
        if (!res.data["detail"]) {
          dispatch("toast", { obj: res.data, variant }, { root: true });
        } else {
          text = res.data.detail;
          title = "Errore durante il salvataggio!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      }

      commit("loading_status", false, { root: true });

      if (res.status == 201) return true;
      return false;
    }
  }
};
