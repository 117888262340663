<template lang="pug">
  div
    b-modal(
      ref="addressmodal"
      title="Indirizzo"
      :ok-only="true"
      ok-title="Chiudi"
    )
      p {{ address_to_show.street }}, {{ address_to_show.city }} {{ address_to_show.province }} {{ address_to_show.postcode }}
      p {{ address_to_show.name }} - {{ address_to_show.cellular }}
    b-modal(
      ref="notemodal"
      title="Note"
      :ok-only="true"
      ok-title="Chiudi"
    )
      b-textarea(:value="note_to_show" readonly)
    b-modal(
      ref="modal"
      id="edit-order"
      title="Modifica"
      centered
      :hide-footer="true"
    )
      validation-observer(ref="observer" v-slot="{ handleSubmit }")
        b-form(@submit.stop.prevent="handleSubmit(onSubmit)")
          b-row
            b-col(cols="6")
              form-input(
                label="Seleziona uno stato" v-model="form.status" type="select"
                :model="form.status" name="status"
                :rules="{required: true}"
                invalid_text="Devi selezionare uno stato"
                :options="statuses"
              )

          .buttons-center
            b-button(type="submit") Salva
            a(href="#" @click="toggle") Torna indietro

    b-table(
      hover
      :items="items"
      :fields="fields"
      :busy="is_busy"
      ref="table"
    )
      template(#table-busy)
        .text-center.my-2
          b-spinner.align-middle

      template(#cell(id)="data")
        span.id(:title="data.item.id") {{ data.item.id }}

      template(#cell(created)="data")
        span {{ data.item.created | moment("DD/MM/YYYY HH:mm") }}

      template(#cell(status)="data")
        span(:class="{danger: data.item.status == 1, success: data.item.status >= 4}")
          | {{ map_status_code(data.item.status) }}
        img.edit-status(
          src="@/assets/edit.svg"
          @click="order_status_modal(data.item)"
        )

      template(#cell(user)="data")
        span.w150(v-if="data.item.owner") {{ data.item.owner.username }}
        span.w150(v-else-if="data.item.manual_data.email" :title="data.item.manual_data.name + ' <'+data.item.manual_data.email+'>'")
          | {{ data.item.manual_data.name }} &lt;{{ data.item.manual_data.email }}&gt;
        span.w150(v-else :title="data.item.manual_data.name")
          | {{ data.item.manual_data.name }}

      template(#cell(phone)="data")
        span.w150(v-if="data.item.owner && data.item.delivery == 'home'") {{ data.item.address.cellular }} 
        span.w150(v-if="data.item.owner") {{ data.item.owner.phone }} {{ data.item.owner.cellular }}
        span.w150(v-else) {{ data.item.manual_data.phone }}

      template(#cell(products)="data")
        .products
          b {{ count_products(data.item) }}
          img(
            src="@/assets/down-arrow.svg"
            @click="show_products(data.item.id)"
            :class="{'_rot': products_showed == data.item.id}"
          )

          div(v-if="products_showed == data.item.id")
            span(v-for="pizza in data.item.pizzas" :key="pizza.id")
              | (n. {{ pizza.quantity }}) {{ pizza.name }}
            span(v-for="extfood in data.item.extras" :key="extfood.id")
              | (n. {{ extfood.quantity }}) {{ extfood.name }}

            div(v-if="data.item.reward")
              b Rewards:
              span(v-for="extfood in data.item.extras_reward" :key="extfood.id")
                | {{ extfood.name }}

              span(v-for="pizza in data.item.pizzas_reward" :key="pizza.id")
                | {{ pizza.name }}

      template(#cell(price)="data")
        span {{ data.item.price }}€

      template(#cell(delivery_time)="data")
        span {{ data.item.delivery_time.substr(0, 5) }}

      template(#cell(payment)="data")
        div(v-if="!data.item.payment && data.item.is_payment_on_delivery")
          .payment.warning Da pagare alla consegna
          b-button.pay(size="sm" @click="pay_order(data.item)") Segna pagato 
            img(src="@/assets/payment-method.png" alt="Pay")
        div(v-else-if="data.item.payment")
          .payment.success(
            :title="'Pagato in data '+serialize_date(data.item.payment.created)"
          )
            span(v-if="data.item.payment.is_online") Pagato online
            span(v-else) Pagato in presenza
        div(v-else)
          span.payment In attesa

      template(#cell(actions)="data")
        b-button(
          v-if="data.item.note != ''"
          size="sm"
          pill
          @click="show_note(data.item.note)"
        ) Leggi note
        b-badge(
          v-if="data.item.delivery == 'take'"
          size="sm"
          pill
          variant="primary"
        ) TAKE AWAY
        b-button(
          v-else
          size="sm"
          pill
          variant="primary"
          @click="show_address(data.item.address)"
        ) Leggi indirizzo

        img.print(
          src="@/assets/print.svg"
          alt="Print"
          @click="print_order(data.item.id)"
        )
</template>

<script>
import { mapActions } from "vuex";
import FormInput from "@/components/FormInput.vue";

export default {
  name: "OrdersTable",
  props: ["items", "is_busy"],
  components: {
    "form-input": FormInput,
  },
  data() {
    return {
      fields: [
        { key: "id", label: "N. ordine" },
        { key: "created", label: "Data" },
        { key: "status", label: "Stato" },
        { key: "user", label: "Utente" },
        { key: "phone", label: "Telefono" }, // this `key` is invalid
        { key: "products", label: "Prodotti" }, // this `key` is invalid
        { key: "price", label: "Totale" },
        { key: "payment", label: "Pagamento" },
        { key: "delivery_time", label: "Orario consegna" },
        { key: "actions", label: "" }, // this `key` is invalid
      ],
      statuses: [
        { value: null, text: "Seleziona uno stato" },
        { value: 0, text: "Ricevuto" },
        { value: 1, text: "Cancellato" },
        { value: 2, text: "In preparazione" },
        { value: 3, text: "In consegna" },
        { value: 4, text: "Consegnato" },
        { value: 5, text: "Completato" },
      ],
      products_showed: null,
      note_to_show: "",
      address_to_show: "",
    };
  },
  methods: {
    show_note(note) {
      this.$refs.notemodal.toggle();
      this.note_to_show = note;
    },
    show_address(address) {
      this.$refs.addressmodal.toggle();
      this.address_to_show = address;
    },
    map_status_code(code) {
      return this.statuses.filter((x) => x.value == code)[0].text;
    },
    serialize_date(date) {
      return this.$moment(date).format("DD/MM/YYYY HH:mm");
    },
    toggle() {
      this.$refs.modal.toggle();
    },
    order_status_modal(item) {
      this.form = { ...item };
      this.toggle();
    },
    onSubmit() {
      this.edit_status(this.form).then((response) => {
        if (response) {
          this.get_orders({ p: this.page_num });
          this.toggle();
        }
      });
    },
    count_products(data) {
      let reward_pizzas = 0;
      let reward_extras = 0;
      let extras = 0;

      const pizzas = data.pizzas
        .map((item) => item.quantity)
        .reduce((a, b) => a + b);

      if (data.extras.length > 0) {
        extras = data.extras
          .map((item) => item.quantity)
          .reduce((a, b) => a + b);
      }

      if (data.reward) {
        if (data.pizzas_reward) {
          reward_pizzas = data.pizzas_reward.length;
        }

        if (data.extras_reward) {
          reward_extras = data.extras_reward.length;
        }
      }
      return pizzas + extras + reward_extras + reward_pizzas;
    },
    show_products(data_id) {
      if (this.products_showed == data_id) {
        this.products_showed = null;
      } else {
        this.products_showed = data_id;
      }
    },
    async print_order(order_id) {
      await this.print(order_id).then((obj) => {
        const newBlob = new Blob([obj.blob], { type: "application/pdf" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
          // For other browsers: create a link pointing to the ObjectURL containing the blob.
          const objUrl = window.URL.createObjectURL(newBlob);
          let link = document.createElement("a");
          link.href = objUrl;
          link.download = obj.filename;
          link.click();
          // For Firefox it is necessary to delay revoking the ObjectURL.
          setTimeout(() => {
            window.URL.revokeObjectURL(objUrl);
          }, 250);
        }
      });
    },
    async pay_order(order) {
      this.mark_payed(order.id).then((response) => {
        if (response) {
          order.payment = { is_online: false };
        }
      });
    },
    ...mapActions("orders", [
      "get_orders",
      "print",
      "edit_status",
      "mark_payed",
    ]),
  },
};
</script>
