<template lang="pug">
  #users.container-cz
    b-modal(
      ref="notifymodal"
      :title="'Invia notifica'+(send_to_all ? ' a tutti': '')"
      centered
      :hide-footer="true"
    )
      validation-observer(ref="observer" v-slot="{ handleSubmit }")
        b-form(@submit.stop.prevent="handleSubmit(newNotification)")
          b-row
            b-col(cols="12")
              form-input(
                label="Titolo" v-model="notification.title" type="text"
                :model="notification.title" name="title"
                :rules="{required: true}"
                invalid_text="Il campo è obbligatorio"
              )
            b-col(cols="12")
              form-input(
                label="Testo" v-model="notification.body" type="textarea"
                :model="notification.body" name="body"
                :rules="{required: true}"
                invalid_text="Il campo è obbligatorio"
              )
          .buttons-center
            b-button(type="submit") Salva
            a(href="#" @click="toggle('notifymodal')") Torna indietro
    b-modal(
      ref="modal"
      id="new-user"
      title="Modifica"
      centered
      :hide-footer="true"
    )
      validation-observer(ref="observer" v-slot="{ handleSubmit }")
        b-form(@submit.stop.prevent="handleSubmit(newUser)")
          b-row
            b-col(cols="6")
              form-input(
                label="Nome utente" v-model="form.name" type="text"
                :model="form.name" name="name"
              )
            b-col(cols="6")
              form-input(
                label="Mail" v-model="form.email" type="text"
                :model="form.email" name="email"
              )
            b-col(cols="6")
              form-input(
                label="Cellulare" v-model="form.cellular" type="text"
                :model="form.cellular" name="cellular"
              )
            b-col(cols="6")
              form-input(
                label="Telefono" v-model="form.phone" type="text"
                :model="form.phone" name="phone"
              )
          .buttons-center
            b-button(type="submit") Salva
            a(href="#" @click="toggle('modal')") Torna indietro

    b-container
      section.header-filters
        div(style="flex-direction: column;")
          b-button(
            @click="new_notify_modal(false)"
            :disabled="selected.length < 1"
            style="margin-bottom: 10px"
          )
            | Invia la notifica
          b-button(@click="new_notify_modal(true)") Invia una notifica a tutti
        #search-box
          b-form
            b-form-group(id="input-text-search")
              b-input(
                id="input-text-search"
                placeholder="ricerca"
                type="text"
                name="q"
                :value="$route.query.q"
              )
            b-button#search-btn(variant="light" type="submit")

    b-container
      b-table(
        hover
        :items="users"
        :fields="fields"
        :busy="is_busy"
        ref="table"
        selectable
        select-mode="multi"
        @row-selected="onRowSelected"
      )
        template(#table-busy)
          .text-center.my-2
            b-spinner.align-middle

        template(#cell(selected)="{ rowSelected }")
          template(v-if="rowSelected")
            span(aria-hidden="true") &check;

        template(#cell(has_verified_email)="data")
          b-badge(variant="success" v-if="data.item.has_verified_email") Sì
          b-badge(variant="danger" v-else) No

        template(#cell(actions)="data")
          .actions(style="display: flex")
            img(
              src="@/assets/edit.svg"
              alt="Modifica"
              title="Modifica"
              @click="edit_user(data.item)"
            )
            .visible
              .b(@click="change_status(data.item)")
                img(
                  src="@/assets/available.svg"
                  alt="Available"
                  v-if="data.item.is_active"
                )
                img(src="@/assets/not-available.svg" alt="Not available" v-else)

    #pagination
      b-pagination(
        v-model="current_page" :total-rows="count" per-page="24"
        first-number last-number @change="change_pag($event)"
      )

</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Icon from "@/components/Icon.vue";
import FormInput from "@/components/FormInput.vue";

export default {
  name: "Users",
  components: {
    icon: Icon,
    "form-input": FormInput,
  },
  data() {
    return {
      is_busy: true,
      current_page: 1,
      page_num: 1,
      selected: [],
      fields: [
        {
          key: "selected",
          label: "",
        },
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "username",
          label: "Username",
        },
        {
          key: "email",
          label: "Mail",
        },
        {
          key: "cellular",
          label: "Cellulare",
        },
        {
          key: "phone",
          label: "Telefono",
        },
        {
          key: "pizzas_count",
          label: "Pizze",
        },
        {
          key: "orders_count",
          label: "Ordini",
        },
        {
          key: "total_points",
          label: "Punti",
        },
        {
          key: "has_verified_email",
          label: "Email verificata",
        },
        {
          key: "actions",
          label: "Azioni",
        },
      ],
      form: {},
      notification: {},
      msg_settings: {
        title: "Azione irreversibile",
        size: "sm",
        buttonSize: "lg",
        okVariant: "danger",
        okTitle: "Sì",
        cancelTitle: "No",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      },
      send_to_all: false,
    };
  },
  computed: {
    ...mapGetters("users", ["users", "count"]),
  },
  methods: {
    change_pag(pagenum) {
      this.current_page = pagenum;
      window.location.href = this.$route.path + "?p=" + this.current_page;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.table.selectAllRows();
    },
    clearSelected() {
      this.$refs.table.clearSelected();
    },
    toggle(ref) {
      this.$refs[ref].toggle();
    },
    new_notify_modal(to_all) {
      this.notification = {};
      this.toggle("notifymodal");
      this.send_to_all = to_all;
    },
    edit_user(item) {
      this.form = { ...item };
      this.toggle("modal");
    },
    newUser() {
      this.edit(this.form).then((response) => {
        if (response) {
          this.get_users({ p: this.page_num });
          this.toggle("modal");
        }
      });
    },
    newNotification() {
      let users;
      if (this.send_to_all) users = "__all__";
      else users = this.selected.map((x) => x.username);

      this.notify({ form: this.notification, users }).then((response) => {
        if (response) {
          this.toggle("notifymodal");
        }
      });
    },
    change_status(user) {
      this.alter_active({ id: user.id, status: !user.is_active }).then(
        (response) => {
          if (response) {
            user.is_active = !user.is_active;
          }
        }
      );
    },
    ...mapActions("users", ["get_users", "edit", "alter_active", "notify"]),
  },
  async mounted() {
    if (this.$route.query["q"]) {
      await this.get_users({ q: this.$route.query.q });
    } else {
      this.page_num = parseInt(this.$route.query["p"]) || 1;

      await this.get_users({ p: this.page_num });

      setTimeout(() => {
        this.$set(this.$data, "current_page", this.page_num);
      }, 1000);
    }
    this.is_busy = false;
  },
};
</script>
