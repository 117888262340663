<template lang="pug">
  #statistics.container-cz
    .filter
      h3 Filtra
      b-form.grid
        h3 Da 
        b-form-datepicker(
          v-model="from_date"
          locale="it"
          hide-header
          :date-format-options="date_format"
          name="from_date"
        )
        h3 A
        b-form-datepicker(
          v-model="to_date"
          locale="it"
          hide-header
          :date-format-options="date_format"
          name="to_date"
        )
        b-button(type="submit" variant="primary") Salva

    b-row
      b-col(cols="4")
        line-chart(:chart-data="users_stats" :options="options")
      b-col(cols="4")
        line-chart(:chart-data="orders_stats" :options="options")
      b-col(cols="4")
        line-chart(:chart-data="feedback_stats" :options="options")
      b-col(cols="4")
        line-chart(:chart-data="ingredients_stats" :options="options")
      b-col(cols="4")
        line-chart(:chart-data="couriers_stats" :options="options")
      b-col(cols="4")
        line-chart(:chart-data="pizzas_stats" :options="options")

    h3 Classifica
    b-row.ranking
      b-col(cols="5")
        b-row
          b-col(cols="8")
            h4 Utenti
          b-col(cols="4")
            h4 Conzate
        b-row(v-for="user in users_top_points.slice(0, 5)" :key="user.id")
          b-col(cols="8")
            .d-flex
              h5 {{ (user.name) ? user.name : user.username }} 
                em(v-if="user.name" style="color: #bbb") ({{ user.username }})
          b-col(cols="4")
            h5
              b {{ user.total_points }}
      b-col(cols="1")
        #separator
      b-col(cols="6")
        b-row
          b-col(cols="8")
            h4 Pizze
          b-col(cols="4")
            h4 Stelle
        b-row(v-for="pizza in pizza_with_high_feedback" :key="pizza.id")
          b-col(cols="8")
            .d-flex
              img(:src="media_base+'/pizzas/'+pizza.image")
              h5 {{ pizza.name }}
          b-col(cols="4")
            h5
              b {{ pizza.feedback || 0 }}/5
</template>

<script>
import LineChart from "@/components/LineChart.js";

import { mapGetters } from "vuex";

export default {
  name: "Statistics",
  components: {
    LineChart
  },
  data() {
    return {
      media_base: process.env.VUE_APP_MEDIA_URL,
      options: {
        responsive: true
      },
      from_date: "",
      to_date: "",
      date_format: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      }
    };
  },
  mounted() {
    const from_date = this.$route.query["from_date"] || "1970-01-01";
    const to_date =
      this.$route.query["to_date"] || this.$moment().format("YYYY-MM-DD");

    this.$store.dispatch("users/stats", { from_date, to_date });
    this.$store.dispatch("orders/stats", { from_date, to_date });
    this.$store.dispatch("orders/feedback_stats", { from_date, to_date });
    this.$store.dispatch("ingredients/stats", { from_date, to_date });
    this.$store.dispatch("users/couriers_stats", { from_date, to_date });
    this.$store.dispatch("pizzas/stats", { from_date, to_date });

    this.from_date = from_date;
    this.to_date = to_date;

    this.$store.dispatch("users/get_users", {
      filters: { order_by_total_points: 1 }
    });
    this.$store.dispatch("pizzas/all", {
      page: 1,
      per_page: 6,
      filters: { order_by_feedback: 1 }
    });
  },
  computed: {
    ...mapGetters("users", {
      users_stats: "stats",
      couriers_stats: "couriers_stats",
      users_top_points: "users"
    }),
    ...mapGetters("orders", {
      orders_stats: "stats",
      feedback_stats: "feedback_stats"
    }),
    ...mapGetters("ingredients", { ingredients_stats: "stats" }),
    ...mapGetters("pizzas", {
      pizzas_stats: "stats",
      pizza_with_high_feedback: "pizzas_list"
    })
  }
};
</script>
