<template lang="pug">
  #pizza
    b-container
      section.header-filters
        b-button(href="https://conzatu.com/conzatu") Aggiungi pizza
        #search-box
          b-form
            b-form-group(id="input-text-search")
              b-input(
                id="input-text-search"
                placeholder="ricerca"
                type="text"
                name="q"
                v-model="q"
              )
            b-button#search-btn(variant="light" type="submit")
        div
          h4 Ordina
          b-form(inline)
            b-form-checkbox(@change="filter()" v-model="filters.order_by_time_ordered")
              | più ordinata
            b-form-checkbox(@change="filter()" v-model="filters.order_by_feedback")
              | più valutata
            b-form-checkbox(@change="filter()" v-model="filters.order_by_created")
              | più tempo

    b-container
      b-row.pizzalist
        b-col(cols="2" v-for="pizza in pizzas_list" :key="pizza.id")
          .pizza
            img.pizza-img(:src="media_base+'/pizzas/'+pizza.image")
            h3 {{ pizza.name }}
            ul
              li ordinata: {{ pizza.times_ordered || 0 }}
              li valutazione: {{ trunc(pizza.feedback) }}

            .visible
              .b(@click="change_status(pizza)")
                img(
                  src="@/assets/available.svg"
                  alt="Available"
                  v-if="pizza.is_visible"
                )
                img(src="@/assets/not-available.svg" alt="Not available" v-else)
              b-badge(variant="success" v-if="pizza.is_visible") disponibile
              b-badge(variant="danger" v-else) non disponibile

    #pagination
      b-pagination(
        v-model="current_page" :total-rows="pizzas_num" per-page="24"
        first-number last-number @change="change_pag($event)"
      )
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Pizza",
  components: {},
  data() {
    return {
      current_page: 1,
      page_num: 1,
      media_base: process.env.VUE_APP_MEDIA_URL,
      filters: {
        order_by_feedback: false,
        order_by_created: false,
        order_by_time_ordered: false,
      },
      q: "",
    };
  },
  computed: {
    ...mapGetters("pizzas", ["pizzas_list", "pizzas_num"]),
  },
  mounted() {
    this.page_num = parseInt(this.$route.query["p"]) || 1;
    this.q = this.$route.query["q"];

    if (this.$route.query["order_by_feedback"])
      this.filters.order_by_feedback = true;
    if (this.$route.query["order_by_created"])
      this.filters.order_by_created = true;
    if (this.$route.query["order_by_time_ordered"])
      this.filters.order_by_time_ordered = true;

    this.$store.dispatch("pizzas/all", {
      page: this.page_num,
      filters: this.filters,
      query: this.q,
    });
    setTimeout(() => {
      this.$set(this.$data, "current_page", this.page_num);
    }, 1000);
  },
  methods: {
    trunc(stars) {
      if (!stars) return 0;

      return stars.toFixed(1);
    },
    change_pag(pagenum) {
      this.current_page = pagenum;
      this.filter();
    },
    filter() {
      let url = this.$route.path;
      if (this.current_page > 1) {
        url += `?p=${this.current_page}&`;
      } else {
        url += `?`;
      }

      for (const filter of Object.keys(this.filters)) {
        if (this.filters[filter]) {
          url += `${filter}=1&`;
        }
      }

      window.location.href = url;
    },
    change_status(pizza) {
      this.alter_visible({ id: pizza.id, status: !pizza.is_visible }).then(
        (response) => {
          if (response) {
            pizza.is_visible = !pizza.is_visible;
          }
        }
      );
    },
    ...mapActions("pizzas", ["alter_visible"]),
  },
};
</script>
