<template lang="pug">
  #settings
    h3 Negozio ConzaTù
      img(src="@/assets/edit.svg" alt="edit" @click="editinfo('info3')")
    b-row
      b-col(cols="12" md="3")
        b-form-checkbox(
          v-model="form.are_orders_enabled"
          switch
          @change="change_order_status()"
        )
          span(v-if="form.are_orders_enabled") Aperto
          span(v-else) Chiuso
      b-col(cols="12" md="3")
        h4 Notifica Mail (inserisci la mail dove ricevere gli ordini) 
        b-input(
          v-model="form.admin_email" v-if="edits == 'info3'"
          :placeholder="info.admin_email" type="admin_email"
        )
        div(v-else)
          p(v-if="info") {{ info.admin_email }}
    b-button.save(variant="primary" v-if="edits == 'info3'" @click="save(3)") Salva
    hr
    h3 Domicilio
      img(src="@/assets/edit.svg" alt="edit" @click="editinfo('shipping')")
    b-row
      b-col(cols="12" md="4")
        h4 Prezzo spedizione
        b-input(
          v-model="form_order.delivery_price" v-if="edits == 'shipping'"
          :placeholder="orders_settings.delivery_price"
        )
        div(v-else)
          p(v-if="orders_settings") {{ orders_settings.delivery_price }}€
      b-col(cols="12" md="4")
        h4 Spedizione gratis a partire da
        b-input(
          v-model="form_order.delivery_price_threshold" v-if="edits == 'shipping'"
          :placeholder="orders_settings.delivery_price_threshold"
        )
        div(v-else)
          p(v-if="orders_settings") {{ orders_settings.delivery_price_threshold }}€
    b-button.save(variant="primary" v-if="edits == 'shipping'" @click="save_order_settings")
      | Salva
    hr
    h3 Pizze ordini manuali
      img(src="@/assets/edit.svg" alt="edit" @click="editinfo('pizza')")
    b-row
      b-col(cols="12" md="4")
        h4 Margherita
        b-input(
          v-model="form_order.margherita" v-if="edits == 'pizza'"
          :placeholder="orders_settings.margherita"
        )
        div(v-else)
          p(v-if="orders_settings") {{ orders_settings.margherita }}
    b-button.save(variant="primary" v-if="edits == 'pizza'" @click="save_order_settings")
      | Salva
    hr
    h3 Informazioni generali
      img(src="@/assets/edit.svg" alt="edit" @click="editinfo('info1')")
    b-row
      b-col(cols="12" md="3")
        h4 Indirizzo
        b-input(
          v-model="form.address" v-if="edits == 'info1'"
          :placeholder="info.address"
        )
        div(v-else)
          p(v-if="info") {{ info.address }}
      b-col(cols="12" md="3")
        h4 P.Iva
        b-input(
          v-model="form.vat" v-if="edits == 'info1'"
          :placeholder="info.vat"
        )
        div(v-else)
          p(v-if="info") {{ info.vat }}
      b-col(cols="12" md="3")
        h4 Email
        b-input(
          v-model="form.email" v-if="edits == 'info1'"
          :placeholder="info.email" type="email"
        )
        div(v-else)
          p(v-if="info") {{ info.email }}
      b-col(cols="12" md="3")
        h4 Sito web
        b-input(
          v-model="form.website" v-if="edits == 'info1'"
          :placeholder="info.website" type="url"
        )
        div(v-else)
          p(v-if="info") {{ info.website }}
      b-col(cols="12" md="3")
        h4 Telefono fisso
        b-input(
          v-model="form.phone" v-if="edits == 'info1'"
          :placeholder="info.phone"
        )
        div(v-else)
          p(v-if="info") {{ info.phone }}
      b-col(cols="12" md="3")
        h4 Mobile
        b-input(
          v-model="form.cellular" v-if="edits == 'info1'"
          :placeholder="info.cellular"
        )
        div(v-else)
          p(v-if="info") {{ info.cellular }}
      b-col(cols="12" md="3")
        h4 Whatsapp
        b-input(
          v-model="form.whatsapp" v-if="edits == 'info1'"
          :placeholder="info.whatsapp"
        )
        div(v-else)
          p(v-if="info") {{ info.whatsapp }}
      b-col(cols="12" md="3")
        h4 Utente telegram
        b-input(
          v-model="form.telegram_username" v-if="edits == 'info1'"
          :placeholder="info.telegram_username"
        )
        div(v-else)
          p(v-if="info") {{ info.telegram_username }}

    b-button.save(variant="primary" v-if="edits == 'info1'" @click="save(1)") Salva
    hr
    h3 Contatti e social
      img(src="@/assets/edit.svg" alt="edit" @click="editinfo('info2')")
    b-row
      b-col(cols="12" md="4")
        h4 Facebook
        b-input(
          v-model="form.facebook" v-if="edits == 'info2'"
          :placeholder="info.facebook"
        )
        div(v-else)
          p(v-if="info") {{ info.facebook }}
      b-col(cols="12" md="4")
        h4 Instagram
        b-input(
          v-model="form.instagram" v-if="edits == 'info2'"
          :placeholder="info.instagram"
        )
        div(v-else)
          p(v-if="info") {{ info.instagram }}
      b-col(cols="12" md="4")
        h4 YouTube
        b-input(
          v-model="form.youtube" v-if="edits == 'info2'"
          :placeholder="info.youtube"
        )
        div(v-else)
          p(v-if="info") {{ info.youtube }}
    b-button.save(variant="primary" v-if="edits == 'info2'" @click="save(2)") Salva
    hr
    h3 Account
      img(src="@/assets/edit.svg" alt="edit" @click="editinfo('user')")
    b-row
      b-col(cols="12" md="3")
        h4 Mail
        // TODO: wait ch9440 for fix
        b-input(
          v-model="form.user.email" v-if="edits == 'user'"
          :placeholder="user.email" readonly
        )
        div(v-else)
          p(v-if="user") {{ user.email }}
      b-col(cols="12" md="8")
        h4 Password
        div(v-if="edits != 'user'")
          p ********
        validation-observer(
          ref="observer"
          v-slot="{ handleSubmit }"
          v-else
        )
          b-form(@submit.stop.prevent="handleSubmit(handleNewPassword)")
            b-row
              b-col(cols="12" md="4")
                h6 Password attuale
                form-input(
                  label="" v-model="form.password.old_password" type="password"
                  :model="form.password.old_password" name="old_password"
                  :rules="{required: true}"
                  invalid_text="Inserisci la tua attuale password"
                )
              b-col(cols="12" md="4")
                h6 Nuova password
                form-input(
                  label="" v-model="form.password.new_password1" type="password"
                  :model="form.password.new_password1" name="new_password1"
                  :rules="{required: true, min: 8}"
                  invalid_text="La password dev'essere lunga minimo 8 caratteri"
                )
              b-col(cols="12" md="4")
                h6 Ripeti nuova password
                form-input(
                  label="" v-model="form.password.new_password2" type="password"
                  :model="form.password.new_password2" name="new_password2"
                  :rules="{required: true, confirmed: 'new_password1'}"
                  invalid_text="Le due password non coincidono"
                )
            b-button.save(variant="primary" type="submit") Salva
</template>

<script>
import FormInput from "@/components/FormInput.vue";

import { mapGetters } from "vuex";

export default {
  name: "Settings",
  components: {
    "form-input": FormInput,
  },
  data() {
    return {
      edits: "",
      form: {
        user: {},
        password: {},
      },
      form_order: {},
    };
  },
  computed: {
    ...mapGetters("info", ["info", "are_orders_enabled"]),
    ...mapGetters("auth", { user: "get_me" }),
    ...mapGetters("orders", ["orders_settings"]),
  },
  mounted() {
    this.$store.dispatch("info/get");
    this.$store.dispatch("orders/get_settings");
    window.setTimeout(() => {
      this.$set(this.form, "are_orders_enabled", this.are_orders_enabled);
    }, 1000);
  },
  methods: {
    editinfo(type) {
      if (type == this.edits) {
        this.edits = "";
      } else {
        this.edits = type;
      }
    },
    change_order_status() {
      const status = this.form.are_orders_enabled;
      this.$store.dispatch("info/edit_order_enable", status);
    },
    save(type) {
      const payload = this.info;
      if (type == 1) {
        if (this.form.address) payload.address = this.form.address;
        if (this.form.vat) payload.vat = this.form.vat;
        if (this.form.email) payload.email = this.form.email;
        if (this.form.website) payload.website = this.form.website;
        if (this.form.phone) payload.phone = this.form.phone;
        if (this.form.cellular) payload.cellular = this.form.cellular;
        if (this.form.whatsapp) payload.whatsapp = this.form.whatsapp;
        if (this.form.telegram_username)
          payload.telegram_username = this.form.telegram_username;
      } else if (type == 2) {
        if (this.form.facebook) payload.facebook = this.form.facebook;
        if (this.form.instagram) payload.instagram = this.form.instagram;
        if (this.form.youtube) payload.youtube = this.form.youtube;
      } else if (type == 3) {
        if (this.form.admin_email) payload.admin_email = this.form.admin_email;
      }

      this.$store.dispatch("info/edit", { payload });
      this.editinfo("");
      this.form.user = {};
      this.form.password = {};
    },
    save_order_settings() {
      const payload = { ...this.orders_settings };
      if (this.form_order.delivery_price)
        payload.delivery_price = this.form_order.delivery_price;
      if (this.form_order.delivery_price_threshold)
        payload.delivery_price_threshold =
          this.form_order.delivery_price_threshold;
      if (this.form_order.margherita)
        payload.margherita = this.form_order.margherita;

      this.$store.dispatch("orders/save_settings", { ...payload });
      this.editinfo("");
      this.form_order = {};
    },
    handleNewPassword() {
      this.$store
        .dispatch("auth/change_password", this.form.password)
        .then(async (res) => {
          const response = await res;
          if (response == 200) {
            this.editinfo("");
            this.form = { user: {}, password: {} };
          }
        });
    },
  },
};
</script>
