const moment = require("moment");

export default {
  namespaced: true,
  state: {
    pizzas: [],
    num: 0,
    per_page: 24,
    sizes: [],
    stats: [],
    pizza_margherita: {},
  },
  getters: {
    pizzas_list: (state) => {
      return state.pizzas;
    },
    pizzas_num: (state) => {
      return state.num;
    },
    sizes_for_select: (state) => {
      return state.sizes.map((size) => {
        return {
          value: size.id,
          text: size.name,
        };
      });
    },
    stats: (state) => {
      const data = {
        labels: state.stats.map((i) => moment(i.x).format("DD/MM/YYYY")),
        datasets: [
          {
            label: "Pizze personalizzate",
            data: [],
            backgroundColor: "#eed2d1",
            borderColor: "#eed2d1",
          },
        ],
      };

      state.stats.forEach((i) => {
        data.datasets[0].data.push(i.y);
      });

      return data;
    },
    margherita: (state) => {
      return state.pizza_margherita;
    },
  },
  mutations: {
    save_pizzas: (state, items) => {
      state.pizzas = items;
    },
    save_pizzas_num: (state, count) => {
      state.num = count;
    },
    save_pizza_sizes: (state, sizes) => {
      state.sizes = sizes;
    },
    save_stats: (state, data) => {
      state.stats = data;
    },
    save_margherita: (state, data) => {
      state.pizza_margherita = data;
    },
  },
  actions: {
    async all({ commit, state, rootState, rootGetters }, data) {
      let url = `${rootState.api}/pizzas/admin/`;
      let method = "GET";
      let body = null;
      const headers = {};
      headers["Authorization"] = `Bearer ${rootGetters["auth/access_token"]}`;

      let per_page = state.per_page;
      if (data.per_page) per_page = data.per_page;

      let return_404 = false;

      if (data.query) {
        url = `${rootState.api}/pizzas/search/admin/`;
        headers["Content-Type"] = "application/json";
        method = "POST";
        body = JSON.stringify({ q: data.query });
      }

      url += `?limit=${per_page}&offset=${(data.page - 1) * per_page}`;

      if (data["filters"]) {
        for (const filter of Object.keys(data.filters)) {
          if (data.filters[filter]) {
            url += `&${filter}`;
          }
        }
      }

      await fetch(url, {
        method,
        headers,
        body,
      }).then(async (response) => {
        const status = await response.status;
        if (status == 404) {
          return_404 = true;
        } else {
          const data = await response.json();
          commit("save_pizzas", data.results);
          commit("save_pizzas_num", data.count);
        }
      });

      if (return_404) return 404;
      return state.pizzas;
    },
    async alter_visible({ commit, rootState, rootGetters }, data) {
      // `data` is { id: <id>, status: ..}
      let to_return = false;
      commit("loading_status", true, { root: true });

      await fetch(`${rootState.api}/pizzas/${data.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify({ is_visible: data.status }),
      })
        .then(async (response) => {
          if ((await response.status) == 200) to_return = true;
        })
        .catch(() => {});

      commit("loading_status", false, { root: true });
      return to_return;
    },
    async get_sizes({ commit, rootState }) {
      let data;
      await fetch(`${rootState.api}/pizzas/sizes/`).then(async (response) => {
        data = await response.json();
      });
      commit("save_pizza_sizes", data.results);
      return data.results;
    },
    async find({ commit, rootState }, data) {
      await fetch(`${rootState.api}/pizzas/u/${data.url}`).then(
        async (response) => {
          if ((await response.status) == 200) {
            if (data.type == "margherita")
              commit("save_margherita", await response.json());
          }
        }
      );
    },
    async stats({ commit, rootState, rootGetters }, payload) {
      await fetch(`${rootState.api}/pizzas/stats/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          if ((await response.status) == 200) {
            commit("save_stats", await response.json());
          }
        })
        .catch(() => {});
    },
  },
};
