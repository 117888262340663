import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import Pizza from "../views/Pizza.vue";
import Users from "../views/Users.vue";
import Settings from "../views/Settings.vue";
import Statistics from "../views/Statistics.vue";
import Ingredients from "../views/Ingredients.vue";
//import Doughs from "../views/Doughs.vue";
import Orders from "../views/Orders.vue";
import OrdersNew from "../views/OrdersNew.vue";
import OrdersRT from "../views/OrdersRT.vue";
import Rewards from "../views/Rewards.vue";
import Extfoods from "../views/Extfoods.vue";
import ExtfoodsGroups from "../views/ExtfoodsGroups.vue";

import loginBeforeEnter from "./loginBeforeEnter";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login
  },
  {
    path: "/a/home",
    name: "Home",
    component: Home,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/a/pizza",
    name: "Pizza",
    component: Pizza,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/a/users",
    name: "Users",
    component: Users,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/a/rewards",
    name: "Rewards",
    component: Rewards,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/a/statistics",
    name: "Statistics",
    component: Statistics,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/a/settings",
    name: "Settings",
    component: Settings,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/a/ingredients",
    name: "Ingredients",
    component: Ingredients,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/a/orders",
    name: "Orders",
    component: Orders,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/a/orders/new",
    name: "OrdersNew",
    component: OrdersNew,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/a/orders/rt",
    name: "OrdersRT",
    component: OrdersRT,
    beforeEnter: loginBeforeEnter
  },
  /*{
    path: "/a/doughs",
    name: "Doughs",
    component: Doughs,
    beforeEnter: loginBeforeEnter
  },*/
  {
    path: "/a/extfoods",
    name: "Extfoods",
    component: Extfoods,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/a/extfoods/groups",
    name: "ExtfoodsGroups",
    component: ExtfoodsGroups,
    beforeEnter: loginBeforeEnter
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
