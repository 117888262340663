<template lang="pug">
  #ingredients.container-cz
    b-modal(
      ref="modal"
      id="new-ingredient"
      :title="(form.id)?'Modifica' : 'Aggiungi'"
      centered
      :hide-footer="true"
    )
      validation-observer(ref="observer" v-slot="{ handleSubmit }")
        b-form(@submit.stop.prevent="handleSubmit(newIngredient)")
          b-row
            b-col(cols="6")
              form-input(
                label="Nome ingrediente" v-model="form.name" type="text"
                :model="form.name" name="name"
                :rules="{required: true}"
                invalid_text="Il campo è obbligatorio"
              )
            b-col(cols="6")
              form-input(
                label="Breve descrizione" v-model="form.description" type="text"
                :model="form.description" name="description"
              )
            b-col(cols="6")
              form-input(
                label="Seleziona un gruppo" v-model="form.group" type="select"
                :model="form.group" name="group"
                :rules="{required: true}"
                invalid_text="Devi selezionare un gruppo"
                :options="groups_for_select"
              )
            b-col(cols="6")
              form-input(
                label="Seleziona un livello" v-model="form.level" type="select"
                :model="form.level" name="level"
                :rules="{required: true}"
                invalid_text="Devi selezionare un livello"
                :options="levels"
              )

          b-row
            b-col(cols="12")
              h4 Seleziona un'icona
              #choose-icon
                b-form-group(v-slot="{ ariaDescribedby }")
                  b-form-radio(
                    v-model="form.icon"
                    :ariaDescribedby="ariaDescribedby"
                    v-for="option in icons"
                    :value="option.value"
                    :key="option.value"
                    :class="{active: form.icon == option.value}"
                  )
                    icon(:i="option.value")

          b-row
            b-col(cols="4")
              b-form-checkbox(
                v-model="form.is_available" switch
              ) Disponibilità
            b-col(cols="4")
              b-form-checkbox(
                v-model="form.can_be_abundant" switch
              ) Abbondante
            b-col(cols="4")
              b-form-checkbox(
                v-model="form.can_be_put_after_baking" switch
              ) Fuori cottura
            b-col(cols="4")
              b-form-checkbox(
                v-model="form.is_lactose_free" switch
              ) Senza lattosio
            b-col(cols="4")
              b-form-checkbox(
                v-model="form.is_gluten_free" switch
              ) Senza glutine


          .buttons-center
            b-button(type="submit") Salva
            a(href="#" @click="toggle") Torna indietro

    b-container
      section.header-filters
        b-button(@click="new_ingredient_modal" :disabled="is_busy")
          | Nuovo ingrediente
        #search-box
          b-form
            b-form-group(id="input-text-search")
              b-input(
                id="input-text-search"
                placeholder="ricerca"
                type="text"
                name="q"
                :value="$route.query.q"
              )
            b-button#search-btn(variant="light" type="submit")

    b-container
      section.table-actions
        a(@click="clearSelected" v-if="selected.length == ingredients.length")
          | Deseleziona tutto
        a(@click="selectAllRows" v-else) Seleziona tutto
        div(v-if="selected.length > 0")
          b-button(@click="delete_n_ingredients()")
            | Elimina {{ selected.length }} ingredienti

      b-table(
        hover
        :items="ingredients"
        :fields="fields"
        :busy="is_busy"
        select-mode="multi"
        selectable
        @row-selected="onRowSelected"
        ref="table"
      )
        template(#table-busy)
          .text-center.my-2
            b-spinner.align-middle

        template(#cell(selected)="{ rowSelected }")
          template(v-if="rowSelected")
            span(aria-hidden="true") &check;

        template(#cell(icon)="data")
          icon(:i="data.item.icon")

        template(#cell(is_available)="data")
          b-form-checkbox(
            v-model="data.item.is_available"
            switch
            @change="switch_edit(data.item)"
          )

        template(#cell(can_be_put_after_baking)="data")
          b-form-checkbox(
            v-model="data.item.can_be_put_after_baking"
            switch
            @change="switch_edit(data.item)"
          )

        template(#cell(can_be_abundant)="data")
          b-form-checkbox(
            v-model="data.item.can_be_abundant"
            switch
            @change="switch_edit(data.item)"
          )

        template(#cell(actions)="data")
          .actions
            img(
              src="@/assets/edit.svg"
              alt="Modifica"
              title="Modifica"
              @click="edit_ingredient(data.item)"
            )
            img(
              src="@/assets/trash-red.png"
              alt="Elimina"
              title="Elimina"
              @click="delete_ingredient(data.item.id)"
            )

    #pagination
      b-pagination(
        v-model="current_page" :total-rows="count" per-page="24"
        first-number last-number @change="change_pag($event)"
      )

</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Icon from "@/components/Icon.vue";
import FormInput from "@/components/FormInput.vue";

export default {
  name: "Ingredients",
  components: {
    icon: Icon,
    "form-input": FormInput,
  },
  data() {
    return {
      is_busy: true,
      current_page: 1,
      page_num: 1,
      selected: [],
      fields: [
        {
          key: "selected",
          label: "",
        },
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "description",
          label: "Descrizione",
        },
        {
          key: "icon",
          label: "Icona",
        },
        {
          key: "is_available",
          label: "Disponibilità",
        },
        {
          key: "can_be_put_after_baking",
          label: "Fuori cottura",
        },
        {
          key: "can_be_abundant",
          label: "Abbondante",
        },
        {
          key: "actions",
          label: "Azioni",
        },
      ],
      levels: [
        { value: 1, text: "Livello 1" },
        { value: 2, text: "Livello 2" },
        { value: 3, text: "Livello 3" },
        { value: 4, text: "Livello 4" },
        { value: 5, text: "Livello 5" },
      ],
      icons: [
        { value: "acciughe" },
        { value: "asparagi" },
        { value: "avocado" },
        { value: "basilico" },
        { value: "bresaola" },
        { value: "broccoli" },
        { value: "burrata" },
        { value: "capperi" },
        { value: "caprino" },
        { value: "champignon" },
        { value: "ciliegino" },
        { value: "cipollarossa" },
        { value: "cipolletta" },
        { value: "cremacarciofi" },
        { value: "cremapistacchio" },
        { value: "cremazucca" },
        { value: "fagioli" },
        { value: "friarelli" },
        { value: "funghipleutorus" },
        { value: "gorgonzola" },
        { value: "grana" },
        { value: "granapadano" },
        { value: "granellapistacchio" },
        { value: "lardo" },
        { value: "lattughino" },
        { value: "mais" },
        { value: "melanzanefrittegrigliate" },
        { value: "misticanza" },
        { value: "mortadella" },
        { value: "mozzarella" },
        { value: "noci" },
        { value: "oliooliva" },
        { value: "olive" },
        { value: "origano" },
        { value: "patatine" },
        { value: "pere" },
        { value: "philadelphia" },
        { value: "pinoli" },
        { value: "piselli" },
        { value: "pomodoro" },
        { value: "pomodorogiallo" },
        { value: "prezzemolo" },
        { value: "prosciuttocotto" },
        { value: "prosciuttocrudo" },
        { value: "radicchio" },
        { value: "ricotta" },
        { value: "ricottasalata" },
        { value: "rucola" },
        { value: "salame" },
        { value: "salmone" },
        { value: "salsiccia" },
        { value: "scamorza" },
        { value: "spada" },
        { value: "speck" },
        { value: "spinaci" },
        { value: "tonno" },
        { value: "uovosodo" },
        { value: "wurstel" },
      ],
      form: { is_available: false, level: null, group: null },
    };
  },
  computed: {
    ...mapGetters("ingredients", ["ingredients", "count", "groups_for_select"]),
  },
  methods: {
    change_pag(pagenum) {
      this.current_page = pagenum;
      window.location.href = this.$route.path + "?p=" + this.current_page;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.table.selectAllRows();
    },
    clearSelected() {
      this.$refs.table.clearSelected();
    },
    toggle() {
      this.$refs.modal.toggle();
    },
    new_ingredient_modal() {
      this.form = { is_available: false, level: null, group: null };
      this.toggle();
    },
    edit_ingredient(item) {
      this.form = { ...item };
      this.toggle();
    },
    delete_ingredient(id) {
      this.$bvModal
        .msgBoxConfirm("Sicuro di volere eliminare questo ingrediente?", {
          title: "Azione irreversibile",
          size: "sm",
          buttonSize: "lg",
          okVariant: "danger",
          okTitle: "Sì",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.delete(id).then((response) => {
              if (response) {
                this.get_ingredients({ p: this.page_num });
              }
            });
          }
        })
        .catch(() => {});
    },
    async delete_n_ingredients() {
      let skip = false;
      await this.$bvModal
        .msgBoxConfirm(
          `Sicuro di volere eliminare ${this.selected.length} ingredienti?`,
          {
            title: "Azione irreversibile",
            size: "sm",
            buttonSize: "lg",
            okVariant: "danger",
            okTitle: "Sì",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          skip = value;
        })
        .catch(() => {});

      if (!skip) return false;

      this.is_busy = true;
      for (const ingredient of this.selected) {
        await this.delete(ingredient.id);
      }

      await this.get_ingredients({ p: this.page_num });
      this.is_busy = false;
    },
    newIngredient() {
      if (!this.form.id) {
        this.create(this.form).then((response) => {
          if (response) {
            this.toggle();
          }
        });
      } else {
        this.edit(this.form).then((response) => {
          if (response) {
            this.get_ingredients({ p: this.page_num });
            this.toggle();
          }
        });
      }
    },
    switch_edit(form) {
      this.edit(form).then((response) => {
        if (response) {
          this.get_ingredients({ p: this.page_num });
        }
      });
    },
    ...mapActions("ingredients", [
      "get_ingredients",
      "edit",
      "get_groups",
      "create",
      "delete",
    ]),
  },
  async mounted() {
    await this.get_groups();

    if (this.$route.query["q"]) {
      await this.get_ingredients({ q: this.$route.query.q });
    } else {
      this.page_num = parseInt(this.$route.query["p"]) || 1;

      await this.get_ingredients({ p: this.page_num });

      setTimeout(() => {
        this.$set(this.$data, "current_page", this.page_num);
      }, 1000);
    }
    this.is_busy = false;
  },
};
</script>
