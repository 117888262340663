export default {
  namespaced: true,
  state: {
    doughs: [],
    count: 0
  },
  getters: {
    doughs: state => {
      return state.doughs;
    },
    count: state => {
      return state.count;
    }
  },
  mutations: {
    save_doughs: (state, data) => {
      state.doughs = data["results"];
      state.count = data["count"];
    },
    insert_dough: (state, item) => {
      state.doughs.push(item);
      state.count++;
    }
  },
  actions: {
    async get_doughs({ commit, rootState }, data) {
      let page = 1;
      let limit = 24;
      let query = "";

      if (data.p) {
        page = data.p;
      } else if (data.q) {
        limit = 1000;
        query = data.q;
      }

      await fetch(
        `${rootState.api}/pizzas/doughs/?limit=${limit}&offset=${(page - 1) *
          limit}`
      ).then(async response => {
        const items = await response.json();
        if (query == "") {
          commit("save_doughs", items);
        } else {
          query = query.toLowerCase();
          const items_filtered = { count: 0, results: [] };
          for (const item of items.results) {
            if (item.name.toLowerCase().indexOf(query) > -1) {
              items_filtered.results.push(item);
            }
          }
          commit("save_doughs", items_filtered);
        }
      });
    },
    async edit({ commit, dispatch, rootState, rootGetters }, item) {
      commit("loading_status", true, { root: true });

      let res = { status: 0, data: {} };

      await fetch(`${rootState.api}/pizzas/doughs/${item.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        },
        body: JSON.stringify(item)
      })
        .then(async response => {
          res.data = await response.json();
          res.status = await response.status;
        })
        .catch(e => {
          res.data = { detail: "Errore generico, non sappiamo dove" };
          res.status = e.status;
        });

      let text = "Impasto modificato con successo",
        title = "Impasto salvato!",
        variant = "success";
      if (res.status == 200) {
        dispatch("toast", { text, title, variant }, { root: true });

        commit("insert_dough", res.data);
      } else {
        variant = "danger";
        if (!res.data["detail"]) {
          dispatch("toast", { obj: res.data, variant }, { root: true });
        } else {
          text = res.data.detail;
          title = "Errore durante il salvataggio!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      }

      commit("loading_status", false, { root: true });

      if (res.status == 200) return true;
      return false;
    },
    async delete({ commit, dispatch, rootState, rootGetters }, id) {
      commit("loading_status", true, { root: true });

      let res = { status: 0, data: {} };

      await fetch(`${rootState.api}/pizzas/doughs/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        }
      })
        .then(async response => {
          res.status = await response.status;
          if (res.status != 204) res.data = await response.json();
        })
        .catch(e => {
          res.status = e.status;
          res.data = { detail: e.status };
        });

      let text = "Impasto eliminato con successo",
        title = "Impasto eliminato!",
        variant = "success";
      if (res.status == 204) {
        dispatch("toast", { text, title, variant }, { root: true });
      } else {
        variant = "danger";

        if (!res.data["detail"]) {
          dispatch("toast", { obj: res.data, variant }, { root: true });
        } else {
          text = res.data.detail;
          title = "Errore durante il salvataggio!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      }

      commit("loading_status", false, { root: true });

      if (res.status == 204) return true;
      return false;
    },
    async create({ commit, dispatch, rootState, rootGetters }, form) {
      commit("loading_status", true, { root: true });

      let res = { status: 0, data: {} };

      await fetch(`${rootState.api}/pizzas/doughs/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        },
        body: JSON.stringify(form)
      })
        .then(async response => {
          res.data = await response.json();
          res.status = await response.status;
        })
        .catch(e => {
          res.status = e.status;
        });

      let text = "Impasto inserito con successo",
        title = "Impasto salvato!",
        variant = "success";
      if (res.status == 201) {
        dispatch("toast", { text, title, variant }, { root: true });

        commit("insert_dough", res.data);
      } else {
        variant = "danger";
        if (!res.data["detail"]) {
          dispatch("toast", { obj: res.data, variant }, { root: true });
        } else {
          text = res.data.detail;
          title = "Errore durante il salvataggio!";
          dispatch("toast", { text, title, variant }, { root: true });
        }
      }

      commit("loading_status", false, { root: true });

      if (res.status == 201) return true;
      return false;
    }
  }
};
